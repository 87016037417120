import React from "react";
import maintenance from "../../../images/icon-maintenance.svg";
import facility from "../../../images/icon-facilityResources.svg";
import rental from "../../../images/icon-rentals.svg";
import IT from "../../../images/icon-itSupport.svg";
import supplies from "../../../images/icon-supplies.svg";
import training from "../../../images/icon-training.svg";
import collaboration from "../../../images/icon-collaboration.svg";
import transportation from "../../../images/icon-transportation.svg";
import newBuild from "../../../images/icon-projects-newBuild.svg";
import closures from "../../../images/icon-roadClosures.svg";
import projectUpdates from "../../../images/icon-projectUpdates.svg";
import organization from "../../../images/icon-organization.svg";
import strategicInitiative from "../../../images/icon-tips.svg";
import shopSpotlight from "../../../images/icon-shop.svg";
import eventsAndTraining from "../../../images/icon-events.svg";
import providers from "../../../images/icon-providers.svg";
import DownArrow from "../../../images/icon-arrow-down.svg";
import UpArrow from "../../../images/icon-arrow-up.svg";
import chevronRight from "../../../images/icon-chevron-right.svg";
import phone from "../../../images/icon-phone.svg";
import email from "../../../images/icon-email.svg";
import download from "../../../images/icon-download.svg";
import service from "../../../images/icon-services.svg";
import news from "../../../images/icon-news.svg";
import document from "../../../images/icon-document.svg";
import page from "../../../images/page-icon.svg";
import project from "../../../images/icon-projects.svg";
import renovation from "../../../images/icon-projects-renovation.svg";
import deferred from "../../../images/icon-projects-deferred.svg";
import retro from "../../../images/icon-projects-retro.svg";
import street from "../../../images/icon-projects-street.svg";
import initiatives from "../../../images/icon-news.svg";
import PropTypes from "prop-types";

export const Icon = ({ category, className, width }) => {
  let img = null;

  switch (category) {
    case "Maintenance + Construction":
      img = maintenance;
      break;
    case "Safety":
      img = safety;
      break;
    case "Facility Resources":
      img = facility;
      break;
    case "Rentals":
      img = rental;
      break;
    case "Supplies":
      img = supplies;
      break;
    case "Training":
      img = training;
      break;
    case "Transportation":
      img = transportation;
      break;
    case "Collaboration":
      img = collaboration;
      break;
    case "Information Technology":
      img = IT;
      break;
    case "New Build":
      img = newBuild;
      break;
    case "Closures":
      img = closures;
      break;
    case "Strategic Initiatives":
      img = strategicInitiative;
      break;
    case "Events and Training":
      img = eventsAndTraining;
      break;
    case "Shop Spotlight":
      img = shopSpotlight;
      break;
    case "Project Updates":
      img = projectUpdates;
      break;
    case "F&S Organization":
      img = organization;
      break;
    case "Provider":
    case "Providers":
      img = providers;
      break;
    case "Up Arrow":
      img = UpArrow;
      break;
    case "Down Arrow":
      img = DownArrow;
      break;
    case "Chevron Right":
      img = chevronRight;
      break;
    case "Phone":
      img = phone;
      break;
    case "Email":
      img = email;
      break;
    case "Download":
      img = download;
      break;
    case "Service":
      img = service;
      break;
    case "News":
      img = news;
      break;
    case "Page":
      img = page;
      break;
    case "Document":
      img = document;
      break;
    case "Project":
      img = project;
      break;
    case "Street Projects":
      img = street;
      break;
    case "Retro Commission":
      img = retro;
      break;
    case "Deferred Maintenance":
      img = deferred;
      break;
    case "Renovation":
      img = renovation;
      break;
    case "Clock":
      img = clock;
      break;
    case "Building":
      img = building;
      break;
    case "Initiatives":
      img = initiatives;
      break;
    default:
      img = null;
  }

  return (
    <img
      alt=""
      style={width && { width: `${width}` }}
      className={className && className}
      src={img}
    />
  );
};

Icon.propTypes = {
  category: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
};

export default Icon;
