import styled from "styled-components";

export const Container = styled.div`
  width:100%;
  max-width:70rem;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top:3rem;
  margin-bottom:3rem;
  text-align:center;

  > div {
  justify-content:space-around;
  }

  svg {
  color: var(--illiniOrange);
  }
  

  .icon{
    width:100%;
    height:auto;
  }

  button > span {
  margin-left: .5rem;
  margin-right: 1rem;
  }
  .iconContainer{
    width:56px;
    height:56px;
    padding:.75rem;
    background:var(--darkGrey);
    margin-right:1rem;
  }
  
  p{
    width:100%;
    text-align:center;
    margin-bottom:3rem;
  }
  
  .btn{
    width:310px;
    justify-content:space-between;
  }

  .btn--transparent{
    margin-top 3rem;
    width:auto;
  }

  .btn > div > img{
    margin-right:.5rem;
  }

  .btn > img {
    margin-right:.5rem;
  }
  .btn > img:nth-child(2){
    float:right;
  }

  @media(max-width:991px){

  }

  @media(max-width:480px){


  }
`;

export const Log = styled.div`
  width: 90%;
`;
