import styled, { css } from "styled-components";
import {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

import LocalNetID from "./LocalNetID";

const LocalDetail = forwardRef((props, ref) => {
  const {
    value,
    lookup,
    label,
    item,
    type = "text",
    maxLength = 0,
    readonly = false,
    row = "span 1",
    column = "span 1",
    width = "100%",
  } = props;

  const [currValue, setCurrValue] = useState(value);
  const [initialValue, setInitialValue] = useState(value);
  const [touched, setTouched] = useState(false);

  const inputRef = useRef(null);

  // Update input value when initialValue changes externally
  useEffect(() => {
    setCurrValue(value);
    setInitialValue(value);
  }, []);

  useImperativeHandle(ref, () => ({
    resetValue: () => {
      setTouched(false);
      setCurrValue(initialValue);
    },
  }));

  const handleChange = (item, e) => {
    let wrkStr = e.target.value.toString();

    switch (type) {
      case "checkbox":
        wrkStr = e.target.checked ? "1" : "0";
        break;
      case "int":
        wrkStr = wrkStr.replace(/\D/g, "");
        break;
    }

    if (
      maxLength === null ||
      maxLength === undefined ||
      maxLength !== 0 ||
      maxLength >= wrkStr.length
    ) {
      wrkStr = wrkStr.substring(0, maxLength);
    }

    const result = ["int", "checkbox"].includes(type)
      ? wrkStr == ""
        ? 0
        : Number(wrkStr)
      : wrkStr;

    setCurrValue(result);
    props.onChange(item, result);
    setTouched(true);
  };

  const procesUIN = (result) => {
    setTouched(true);
    props.onChange(item, result);
  };

  return (
    <Wrapper row={row} column={column}>
      <label>
        <h5>{label}</h5>
      </label>
      <Container width={width} readonly={readonly}>
        {type !== "netid" ? (
          <StyledInput
            ref={inputRef}
            type={type === "int" ? "text" : type}
            checked={(currValue ?? "1") == "1" ? true : false}
            value={currValue}
            onChange={(e) => handleChange(item, e)}
            readOnly={readonly}
            touched={touched}
          />
        ) : (
          <LocalNetID
            onSelectValue={procesUIN}
            displayValue={value}
            lookupValueNetID={lookup}
            readOnly={false}
            touched={touched}
          />
        )}
      </Container>
    </Wrapper>
  );
});
export default LocalDetail;

const Wrapper = styled.span`
  float: left;
  margin: 10px;
  grid-row: ${(props) => props.row || "span 1"};
  grid-column: ${(props) => props.column || "span 1"};
`;

const Container = styled.div`
  min-height: 25px;
  min-width: 50px;
  width: ${(props) => props.width}px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #b3b3b3;
  background: #ffffff;
  border-radius: 5px;

  ${(props) =>
    props.readonly &&
    css`
      background: #d0d0d0;
      border: #f8f8f8;
    `}
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  flex: 1 0;
  border: 0;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  ${({ touched }) =>
    touched &&
    `
        font-weight: 700;
      
    `}
`;
