import React from "react";
import Input from "components/Common/Input/Input";
import { StyledContainer } from "./AdditionalInformationStep.styles";

const AdditionalInformationStep = ({
  afterHoursAccess,
  setAfterHoursAccess,
  afterHoursReason,
  setAfterHoursReason,
  additionalInformation,
  setAdditionalInformation,
}) => {
  const handleCheckbox1Change = () => {
    setAfterHoursAccess(!afterHoursAccess);
  };

  const handleInput1Change = (e) => {
    setAfterHoursReason(e.target.value);
  };

  const handleInput2Change = (e) => {
    setAdditionalInformation(e.target.value);
  };

  return (
    <StyledContainer>
      <h3>Additional Information</h3>
      <div style={{ display: "flex", marginBottom: "1rem" }}>
        <label>Do you need After Hours Access?:</label>
        <input
          type="checkbox"
          checked={afterHoursAccess}
          onChange={handleCheckbox1Change}
        />
      </div>

      {afterHoursAccess && (
        <div>
          <Input
            label="After Hours Access Reason: "
            placeholder="access reason..."
            maxlength="256"
            width="100%"
            align="left"
            value={afterHoursReason}
            onChange={handleInput1Change}
          />
        </div>
      )}
      <div>
        <Input
          label="Additonal Information to help process: "
          width="100%"
          maxlength="256"
          placeholder="additional information..."
          align="left"
          onChange={handleInput2Change}
          value={additionalInformation}
        />
      </div>
    </StyledContainer>
  );
};

export default AdditionalInformationStep;
