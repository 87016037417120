import styled from "styled-components";

export const StyledAction = styled.div`
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  background: #3f833d;
  color: #fff;
  text-transform: uppercase;
  display: inline;

  flex: 1;

  font-size: 1.2rem;
`;

export const StyledPanel = styled.div`
  text-align: center;
  /* font-size: 2rem; */
  margin: 0.5rem;
  background: var(--white);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 20rem;

  a {
    text-decoration: none;
    font-style: normal;
  }

  p {
    font-size: 2rem;
    color: var(--illiniDarkBlue);
    margin-bottom: 0;
  }
`;

export const StyledHeader = styled.h3`
  margin-bottom: 0.5rem;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 10px;
  td,
  th {
    font-family: "Source Sans Pro";
    font-size: 16px;
    padding: 2px;
    text-align: left;
  }

  th {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #13294b;
  }
`;
