import styled from "styled-components";

export const Container = styled.div`
  padding: 0px 5px 0px 0px;
  margin: 0px 0px 0px 5px;

  label {
    font-weight: 700;
  }
`;

export const SelectContainer = styled.div`
  min-height: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  border: 1px solid #b3b3b3;
  background: #ffffff;
  border-radius: 5px;
  margin: 0px;
`;

export const SelectDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;

  position: relative;
  &:after {
    content: "▼";
    z-index: 0;
  }

  &:after {
    position: absolute;
    right: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    color: #ff522e;
    font-size: 1rem;
    z-index: 0;
    pointer-events: all;
  }
`;
