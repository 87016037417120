import styled from "styled-components";

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background: #fff;

  border: 2px solid var(--illiniDarkBlue);
  border-radius: 4px;

  padding: 5px 10px;

  margin: 0.2rem;
  font-size: 1.2rem;

  &:hover {
    background: #13294b;
    color: #fff;
  }

  &:disabled {
    background: #fff;
    color: #999;
    border-color: #13294b;
    opacity: 0.5;
  }
`;
