import styled from "styled-components";

export const InputContainer = styled.div`
  position: relative;
  width: 300px;
`;

export const Inputs = styled.input`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

export const Input = styled.input`
  box-sizing: border-box;
  flex: 1 0;
  border: 0;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  ${({ touched }) =>
    touched &&
    `
      font-weight: 700;
    
  `}
`;

export const Dropdown = styled.ul`
  position: absolute;
  top: 100%;
  left: -5;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
`;

export const DropdownItem = styled.li`
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;

  &:hover {
    background-color: #f0f0f0;
  }

  &:last-child {
    border-bottom: none;
  }
`;
