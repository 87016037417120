import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Icon from "../Icon/Icon";

const MenuItem = (mainItem) => {
  const [click, setClick] = useState(false);
  const handleClick = () => {
    removeClass("nav-active");

    setClick(!click);
  };

  function removeClass(className) {
    const elements = document.querySelectorAll(`.${className}`);

    elements.forEach((element) => {
      element.classList.remove(className);
    });
  }

  function keydown(event) {
    if (event.keyCode === 13) {
      removeClass("nav-active");
      setClick(!click);
    }
  }

  return (
    // build menu item and child menu
    <li key={mainItem.mainItem.id}>
      {mainItem.mainItem.childItems.nodes.length !== 0 ? (
        <button
          href={null}
          onClick={handleClick}
          onKeyDown={keydown}
          tabIndex={mainItem.i}
          className={click ? "nav nav-active" : "nav"}
          style={{ color: "var(--illiniDarkBlue" }}
        >
          {mainItem.mainItem.label}

          {mainItem.mainItem.childItems.nodes.length !== 0 && click ? (
            <div>
              <Icon className="icon" category="Up Arrow" />
            </div>
          ) : (
            <div>
              <Icon className="icon" category="Down Arrow" />
            </div>
          )}

          {mainItem.mainItem.childItems.nodes.length !== 0 ? (
            <ul className={click ? "activeUL" : ""}>
              {mainItem.mainItem.childItems.nodes.map((childItem) => {
                return (
                  <li key={childItem.id}>
                    {childItem.url.charAt(0) === "/" ? (
                      <NavLink
                        to={childItem.url}
                        activeclassname="nav-active"
                        className="secondaryLink"
                      >
                        {childItem.label}
                      </NavLink>
                    ) : (
                      <NavLink to={childItem.url} className="secondaryLink">
                        {childItem.label}
                      </NavLink>
                    )}
                  </li>
                );
              })}
            </ul>
          ) : null}
        </button>
      ) : (
        <NavLink
          className="mainLink"
          index={mainItem.mainItem.id}
          to={mainItem.mainItem.url}
        >
          {mainItem.mainItem.label}
        </NavLink>
      )}
    </li>
  );
};

export default MenuItem;
