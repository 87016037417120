import { useState } from "react";
import { useQuery } from "react-query";
//import { getBuildingKeys } from "hooks/Rooms/roomData";
import { getRoomGeneral } from "hooks/Rooms/roomGeneral";
import {
  StyledTable,
  TableRow,
  TableHead,
  TableBody,
  RoundLeft,
} from "../../Common/Styles/TableStyles";
import ReactLoading from "react-loading";
import Row from "components/Common/TableRow/TableRow";

import { SVGRoomList } from "components/Context/getRoomsSVG/getRoomsSVG";

import { TH } from "./getRoomKeysTables.style";

const GetRoomKeysTable = (props) => {
  const [queryId, setQueryId] = useState(null);

  const { id = "", reloadQuery = false } = props;

  const ary = id.split("-");
  const buildingId = ary[0];
  const floorId = ary[1];
  const roomId = ary[2];

  const { isLoading, isError, error, data } = useQuery(
    ["idBuildingKeys", id],
    () =>
      getRoomGeneral(
        "key",
        "0",
        null,
        null,
        buildingId,
        floorId,
        roomId,
        true,
        true,
        true
      ),
    {
      enabled: queryId !== id || reloadQuery === true,
      onSuccess: () => {
        setQueryId(id);
      },
      onError: (error) => {
        console.error(error);
      },
      fetchPolicy: "cache",
    }
  );

  let rows;
  if (isLoading) {
    rows = (
      <TableRow>
        <td style={{ textAlign: "center" }} colSpan="6">
          Loading
          <br />
          <ReactLoading type="bubbles" color="#000000" height="300" />
        </td>
      </TableRow>
    );
  } else if (isError) {
    rows = (
      <TableRow>
        <td>{error.message}</td>
      </TableRow>
    );
  } else {
    rows = data?.data?.results
      .sort((a, b) => {
        const aroom = a?.rooms ?? " ";
        const broom = b?.rooms ?? " ";
        const akey = a?.keyCode ?? " ";
        const bkey = b?.keyCode ?? " ";

        // reverse the sort so that keys specifically assigned to a room are on top
        if (aroom > broom) {
          return -1;
        } else if (aroom < broom) {
          return 1;
        }

        if (akey < bkey) {
          return -1;
        } else if (akey > bkey) {
          return 1;
        }

        return 0;
      })
      .map((assignment, index) => {
        let user = [
          assignment?.key_type,
          assignment?.bldg,
          assignment?.bldgName,
          assignment?.keyCode,
          <SVGRoomList inputString={assignment?.rooms} />,
          assignment?.inventory ?? 0,
        ];

        return <Row key={index} data={user} index={index} />;
      });
  }

  return (
    <>
      <StyledTable>
        <TableHead>
          <tr>
            <RoundLeft scope="col" width="1%">
              n
            </RoundLeft>
            <TH scope="col" width="5%">
              Type
            </TH>
            <TH scope="col" width="5%">
              Bldg
            </TH>
            <TH scope="col" width="30%">
              Building Name
            </TH>
            <TH scope="col" width="20%">
              Key
            </TH>
            <TH scope="col" width="20%">
              Rooms
            </TH>
            <TH scope="col" width="10%">
              Available Keys
            </TH>
          </tr>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </StyledTable>
    </>
  );
};
export default GetRoomKeysTable;
