import { useQuery } from "react-query";
import { getSearchUser } from "hooks/User/userData";
import Button from "components/Common/Button/Button";
import { useState } from "react";
import { Input } from "./UsersList.style";

import { StyledTable } from "components/Common/Table/Table.style";
import {
  getSelectableKeyholderHeader,
  getSelectableKeyholderRows,
} from "hooks/Keyholders/keyholdersTableFunctions";

import ReactLoading from "react-loading";
import { TableRow } from "../../../components/Common/Styles/TableStyles";

const UsersList = ({ keyholder, setKeyholder }) => {
  const [enabled, setEnabled] = useState(false);

  const [searchfirstname, setSearchFirstName] = useState("");
  const [searchlastname, setSearchLastName] = useState("");
  const [searchnetid, setSearchNetId] = useState("");
  const [searchuin, setSearchUIN] = useState("");

  const { isLoading, isFetching, isSuccess, isError, error, data } = useQuery(
    ["users"],
    () =>
      getSearchUser(searchfirstname, searchlastname, searchnetid, searchuin),
    {
      onSuccess: (data) => {},
      enabled: enabled,
      refetchOnWindowFocus: true,
    }
  );

  const handleFirstName = (e) => {
    setEnabled(false);
    setSearchFirstName(e.target.value.trim());
  };

  const handleLastName = (e) => {
    setEnabled(false);
    setSearchLastName(e.target.value.trim());
  };

  const handleNetId = (e) => {
    setEnabled(false);
    setSearchNetId(e.target.value.trim());
  };

  const handleUIN = (e) => {
    setEnabled(false);
    setSearchUIN(e.target.value.trim());
  };

  const handleSearch = (e) => {
    setEnabled(true);
  };

  let rows;

  let header = getSelectableKeyholderHeader();

  if (isLoading) {
    rows = (
      <StyledTable>
        {header}
        <tbody>
          <TableRow key={1}>
            <td style={{ textAlign: "center" }} colSpan="6">
              Loading...
              <br />
              <ReactLoading type="bubbles" color="#000000" height="300" />
            </td>
          </TableRow>
        </tbody>
      </StyledTable>
    );
  } else if (isFetching) {
    rows = (
      <StyledTable>
        {header}
        <tbody>
          <TableRow key={1}>
            <td style={{ textAlign: "center" }} colSpan="6">
              Searching...
              <br />
              <ReactLoading type="bubbles" color="#000000" height="300" />
            </td>
          </TableRow>
        </tbody>
      </StyledTable>
    );
  } else if (isError) {
    rows = error.message;
  } else if (isSuccess) {
    const wrkList = data?.data?.list?.filter((entry) => {
      return (entry?.uin ?? "") != "";
    });

    let finalRows = wrkList
      ? getSelectableKeyholderRows({
          users: wrkList,
          keyholder: keyholder,
          setKeyholder: setKeyholder,
        })
      : null;

    if (finalRows?.length > 0) {
      rows = (
        <StyledTable>
          {header}
          <tbody>{finalRows}</tbody>
        </StyledTable>
      );
    } else {
      rows = (
        <>
          <StyledTable>{header}</StyledTable>
          <div style={{ width: "100%", textAlign: "center" }}>
            <p>No data found</p>
          </div>
        </>
      );
    }
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "20rem",
          flexWrap: "wrap",
          justifyContent: "center",
          margin: "0 auto",
        }}
      >
        <h3 style={{ textAlign: "center" }}>Search for Users</h3>
        <div
          style={{
            width: "20rem",
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <label>First Name: </label>
          <Input
            label="First Name"
            value={searchfirstname}
            onChange={(e) => handleFirstName(e)}
          />
        </div>
        <div
          style={{
            width: "20rem",
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <label>Last Name: </label>
          <Input
            label="Last Name"
            value={searchlastname}
            onChange={(e) => handleLastName(e)}
          />
        </div>
        <div
          style={{
            width: "20rem",
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <label>NetId: </label>
          <Input
            label="NetId"
            value={searchnetid}
            onChange={(e) => handleNetId(e)}
          />
        </div>
        <div
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <label>UIN: </label>

          <Input label="UIN" value={searchuin} onChange={(e) => handleUIN(e)} />
        </div>

        <Button label="Search" onClick={(e) => handleSearch(e)} />
      </div>

      <div
        style={{
          flexBasis: "100%",
          width: "0",
        }}
      ></div>
      <div style={{ display: "inline-flex", flexWrap: "wrap", flex: "1" }}>
        {rows}
      </div>
    </>
  );
};
export default UsersList;
