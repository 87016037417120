import React from "react";
import { CardContainer } from "./Card.styles";

export const Card = ({ width, content, justify, padding }) => {
  return (
    <>
      <CardContainer
        style={{
          width: `${width}`,
          justifyContent: `${justify}`,
          padding: `${padding}`,
        }}
      >
        {content}
      </CardContainer>
    </>
  );
};

export default Card;
