import axiosInstance from "hooks/Axios/axiosClient";

// Calls below have not been reviewed

const getRoles = async () => {
  const response = await axiosInstance.get("/api/authz/roles");

  return response;
};

export { getRoles };
