import { StyledLabel, StyledEllipse, Container } from "./Ellipse.style";

const Ellipse = (props) => {
  const { number, status = "", className } = props; //destructuring

  return (
    <Container>
      <StyledEllipse className={className} status={status}>
        <StyledLabel>{number}</StyledLabel>
        {status}
      </StyledEllipse>
    </Container>
  );
};
export default Ellipse;
