import styled, { keyframes } from "styled-components";

const animation = keyframes`
 0% { transform: scale( 1.0 ); background: #F2F2F2; }


100% { transform: scale( 1.05 );  background: #13294B;}


`;
export const StyledButton = styled.button`
  width: 100%;

  margin: 10px;
  padding: 30px;

  border-radius: 8px;
  background: #f2f2f2;
  color: #13294b;

  &:hover {
    background: #13294b;
    color: #fff;

    animation-name: ${animation};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinate;
  }

  h5 {
    display: inline;

    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;

    color: #13294b;
  }
  p {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #849bc1;
  }
`;
