import { useState } from "react";
import Input from "../Input/Input";
import Button from "components/Common/Button/Button";
import {
  StyledPanel,
  StyledTable,
  StyledAction,
  StyledHeader,
} from "./Panel.style";

const Panel = (props) => {
  const { label, data, headers, actions = "", button, title, subTitle } = props; //This allow for not existent props

  const [showBack, setShowBack] = useState("none");
  const [showFront, setShowFront] = useState();

  function handleClick() {
    setShowFront("none");
    setShowBack("");
  }

  return (
    <>
      {headers !== "" && <StyledHeader>{label}</StyledHeader>}
      <StyledPanel style={{ display: showFront }}>
        {title || subTitle ? (
          <h3>
            {title}
            <br />
            {subTitle}
          </h3>
        ) : null}
        <p>{data}</p>
        {headers === "" && <StyledHeader>{label}</StyledHeader>}

        {headers !== "" && (
          <StyledTable>
            {headers.map((header, index) => (
              <tr key={index}>
                <th>{header.label}</th>
                <td>A value</td>
              </tr>
            ))}
          </StyledTable>
        )}
        {actions !== "" && (
          <div
            style={{
              display: "flex",
              margin: "0px",
              padding: "0px",
              background: "#3f833d",
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            &nbsp;
            {actions.map((action, index) => (
              <StyledAction style={{}} onClick={handleClick} key={index}>
                {action}
              </StyledAction>
            ))}
          </div>
        )}
        {button && <Button label="Show Details" onClick={button} />}
      </StyledPanel>

      <StyledPanel style={{ display: showBack }}>
        {data}
        {headers === "" && <StyledHeader>{label}</StyledHeader>}

        {headers !== "" && (
          <StyledTable>
            {headers.map((header, index) => (
              <tr key={index}>
                <th>{header.label}</th>
                <td>
                  <Input />
                </td>
              </tr>
            ))}
          </StyledTable>
        )}
        {actions !== "" && (
          <div
            style={{
              display: "flex",
              margin: "0px",
              padding: "0px",
              background: "#3f833d",
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            &nbsp;
            {actions.map((action, index) => (
              <StyledAction style={{}} onClick={handleClick} key={index}>
                {action}
              </StyledAction>
            ))}
          </div>
        )}
      </StyledPanel>
    </>
  );
};
export default Panel;
