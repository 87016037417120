import { useState, useEffect } from "react";
import AccessStep from "../AccessStep/AccessStep";
import KeyholderStep from "../KeyholderStep/KeyholderStep";
import ReviewStep from "../ReviewStep/ReviewStep";
import KeyWizardSteps from "../KeyWizardStep/KeyWizardSteps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDoorOpen,
  faUser,
  faBuilding,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import Button from "components/Common/Button/Button";
import { createRequest } from "hooks/requestData";
import { useMsal } from "@azure/msal-react";
import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";
import { Container, Container2 } from "./RequestKeys.style";
import AdditionalInformationStep from "../AdditionalInformationStep/AdditonalInformationStep";
import CircularProgress from "@mui/material/CircularProgress";

const getLocalItems = () => {
  let requests = localStorage.getItem("requests");

  if (requests) {
    return JSON.parse(localStorage.getItem("requests"));
  } else {
    return [];
  }
};

const RequestKeys = () => {
  const instance = useMsal();
  const {
    roomsSelected,
    setRoomsSelected,
    buildingsSelected,
    setBuildingsSelected,
    keysSelected,
    setKeysSelected,
    userName,
  } = useGlobalContext();

  const [step, setStep] = useState(1);
  const [requestType, setRequestType] = useState("room");
  const [loading, setLoading] = useState(false);

  const [keyholder, setKeyholder] = useState({
    type: "myself",
    users: [
      {
        userId: userName?.uin,
        firstName: userName?.firstName,
        lastName: userName?.lastName,
      },
    ],
  });

  const [afterHoursAccess, setAfterHoursAccess] = useState(false);
  const [afterHoursReason, setAfterHoursReason] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");

  const [loginUser, setLoginUser] = useState(userName?.uin);

  async function handleSubmit() {
    try {
      setLoading(true);

      //array to store responses from createRequest
      let responses = await Promise.all(
        keyholder.users.map(async (user) => {
          let jsonHeader = JSON.stringify({
            accessHolder: user.userId,
            accessHolderName: user.firstName + " " + user.lastName,
            requestStatusId: 1,
            createdBy: loginUser,
            updatedBy: loginUser,
            submittedBy: loginUser,
            afterHours: afterHoursAccess,
            afterHoursComment: afterHoursReason,
            comment: additionalInformation,
          });

          let jsonBody = JSON.stringify({
            roomId: roomsSelected,
            buildingId: buildingsSelected,
            keyId: keysSelected,
          });

          const res = await createRequest(jsonHeader, jsonBody, instance);

          return res;
        })
      );

      //check responses for errors
      responses.map((response) => {
        if (response.status !== 200) {
          throw new Error("Error submitting request " + response.message);
        }
      });

      resetStates();

      //if no errors, set step to 0 for success message
      setStep(0);
    } catch (error) {
      setLoading(false);
      alert("Error submitting request: " + error);
      console.log(error);
    }
  }

  const resetStates = () => {
    setKeyholder({
      type: "myself",
      users: [
        {
          userId: userName?.uin,
          firstName: userName?.firstName,
          lastName: userName?.lastName,
        },
      ],
    });
    setRoomsSelected([]);
    setBuildingsSelected([]);
    setKeysSelected([]);
    setAfterHoursAccess(false);
    setAfterHoursReason("");
    setAdditionalInformation("");
    localStorage.clear();

    setLoading(false);
  };

  const handleAddroom = (k) => {
    if (items.some((item) => item.roomId === k)) {
      setItems(items.filter((item) => item.roomId !== k));
    } else {
      setItems([...items, { keyId: "", roomId: k }]);
    }
  };

  const checkLocalStorage = (k) => {
    if (items.some((item) => item.roomId === k)) {
      return false;
    } else {
      return true;
    }
  };

  const handleButtonLocked = (step) => {
    switch (step) {
      case 1:
        if (
          roomsSelected?.length === 0 &&
          buildingsSelected?.length === 0 &&
          keysSelected?.length === 0
        ) {
          return true;
        }
        break;
      case 2:
        if ((keyholder?.users?.length ?? 0) === 0) {
          return true;
        }
        break;
      case 3:
        return false;
      case 4:
        return true;
      default:
        return false;
    }
  };

  return (
    <>
      <div className="breadcrumbBar">
        {step !== 0 && (
          <Container>
            {keysSelected.length > 0 && (
              <div
                style={{
                  padding: "1rem",
                  paddingTop: "0",
                  textAlign: "center",
                }}
              >
                <div style={{ marginTop: "5px", fontSiz: "1.25rem" }}>
                  {keysSelected.length}{" "}
                  <FontAwesomeIcon
                    style={{ color: "var(-illiniOrange" }}
                    icon={faKey}
                    onClick={() => setStep(1)}
                  />{" "}
                </div>
              </div>
            )}
            {buildingsSelected.length > 0 && (
              <div
                style={{
                  padding: "1rem",
                  paddingTop: "0",
                  textAlign: "center",
                }}
              >
                <div style={{ marginTop: "5px", fontSiz: "1.25rem" }}>
                  {buildingsSelected.length}{" "}
                  <FontAwesomeIcon
                    style={{ color: "var(-illiniOrange" }}
                    icon={faBuilding}
                    onClick={() => setStep(1)}
                  />{" "}
                </div>
              </div>
            )}
            {roomsSelected.length > 0 && (
              <div
                style={{
                  padding: "1rem",
                  paddingTop: "0",
                  textAlign: "center",
                }}
              >
                <div style={{ marginTop: "5px", fontSiz: "1.25rem" }}>
                  {roomsSelected.length}{" "}
                  <FontAwesomeIcon
                    style={{ color: "var(-illiniOrange" }}
                    icon={faDoorOpen}
                    onClick={() => setStep(1)}
                  />{" "}
                </div>
              </div>
            )}

            <div
              style={{ padding: "1rem", paddingTop: "0", textAlign: "center" }}
            >
              <i
                className={
                  keyholder.type === "myself"
                    ? "fa-solid fa-user fa-2xl"
                    : "fa-solid fa-users fa-2xl"
                }
                style={{ color: "#13294B" }}
              >
                {" "}
              </i>

              <div style={{ marginTop: "5px", fontSiz: "1.25rem" }}>
                {keyholder.users.length}{" "}
                <FontAwesomeIcon
                  style={{ color: "#13294B" }}
                  icon={faUser}
                  onClick={() => {
                    if (roomsSelected?.length > 0) setStep(2);
                  }}
                />{" "}
              </div>
            </div>
          </Container>
        )}
      </div>
      <KeyWizardSteps activeStep={step} setStep={setStep} />

      <Container2>
        {(step === 1 && (
          <AccessStep
            handleAddroom={(newValue) => handleAddroom(newValue)}
            checkLocalStorage={checkLocalStorage}
            requestType={requestType}
            setRequestType={setRequestType}
          />
        )) ||
          (step === 2 && (
            <KeyholderStep keyholder={keyholder} setKeyholder={setKeyholder} />
          )) ||
          (step === 3 && (
            <AdditionalInformationStep
              afterHoursAccess={afterHoursAccess}
              setAfterHoursAccess={setAfterHoursAccess}
              additionalInformation={additionalInformation}
              setAdditionalInformation={setAdditionalInformation}
              afterHoursReason={afterHoursReason}
              setAfterHoursReason={setAfterHoursReason}
            />
          )) ||
          (step === 4 && (
            <>
              <ReviewStep
                keyholder={keyholder}
                setKeyholder={setKeyholder}
                step={setStep}
                afterHoursAccess={afterHoursAccess}
                additionalInformation={additionalInformation}
                afterHoursReason={afterHoursReason}
              />

              <Button
                label={
                  loading ? (
                    <>
                      <span>Submit Request {"  "}</span>{" "}
                      <CircularProgress style={{ marginLeft: "1rem" }} />
                    </>
                  ) : (
                    "Submit Request"
                  )
                }
                width="75%"
                className="submitButton"
                disabled={loading}
                onClick={() => handleSubmit()}
              ></Button>
            </>
          ))}
      </Container2>
      <div style={{ margin: "auto", width: "100%", maxWidth: "1180px" }}>
        {step === 0 && (
          <div>
            <h2 style={{ color: "#13294B" }}>
              Thank you for your submission!&nbsp;
              <span style={{ color: "#FF552E" }}>You are all set.</span>
            </h2>
            <p>Check your email inbox for confirmation of your submission.</p>
            <p>
              Your request is now in the approval process. We will send updates
              during the approval process steps to all key holders as necessary
              to keep them informed.
            </p>
            <div style={{ display: "flex" }}>
              <Button
                onClick={() => setStep(1)}
                label="Begin a New Request"
              ></Button>
            </div>
          </div>
        )}
      </div>
      {step !== 0 && (
        <div style={{ margin: "auto", width: "100%", maxWidth: "1180px" }}>
          <div style={{ float: "right", height: "100px" }}>
            <Button
              onClick={() => setStep(step + 1)}
              label="Next Step"
              disabled={handleButtonLocked(step)}
            ></Button>
          </div>

          <div style={{ float: "left", height: "100px" }}>
            <Button
              onClick={() => setStep(step - 1)}
              label="Previous Step"
              disabled={step <= 1}
            ></Button>
          </div>
        </div>
      )}
    </>
  );
};

export default RequestKeys;
