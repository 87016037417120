import RoomsSelected from "../RoomsSelected/RoomsSelected";
import KeyholdersSelected from "../KeyholdersSelected/KeyholdersSelected";

import { Container } from "./ReviewStep.style";
import BuildingsSelected from "components/RequestAKey/BuildingsSelected/BuildingsSelected";
import KeysSelectedTable from "components/RequestAKey/KeysSelected/KeysSelectedTable";

import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";

const KeyRequestStep = ({
  keyholder,
  setKeyholder,
  afterHoursReason,
  afterHoursAccess,
  additionalInformation,
}) => {
  // MAKE SURE TO PASS BUILDING ID WITH KEY TO BACKEND

  const { keysSelected, buildingsSelected, roomsSelected } = useGlobalContext();

  return (
    <>
      <Container>
        <h2>Access for </h2>
        <KeyholdersSelected keyholder={keyholder} setKeyholder={setKeyholder} />

        <h2>Requesting Access to</h2>

        {roomsSelected.length > 0 && (
          <>
            <h3>Rooms Selected</h3>
            <RoomsSelected />
          </>
        )}

        {buildingsSelected.length > 0 && (
          <>
            <h3>Buildings Selected</h3>
            <BuildingsSelected />
          </>
        )}

        {keysSelected.length > 0 && (
          <>
            <h3>Labeled Keys Selected</h3>
            <KeysSelectedTable />
          </>
        )}

        <h2>Additional Information</h2>

        {additionalInformation || afterHoursAccess ? (
          <>
            {additionalInformation && (
              <p>
                <strong>Additional Information:</strong> {additionalInformation}
              </p>
            )}
            {afterHoursAccess && (
              <p>
                <strong>After Hours Access:</strong> Yes
                <br />
                <strong>After Hours Access Reason:</strong> {afterHoursReason}
              </p>
            )}
          </>
        ) : (
          <p>No Additional Information Provided</p>
        )}
      </Container>
    </>
  );
};

export default KeyRequestStep;
