import styled from "styled-components";

export const DarkBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.35);
  width: 100vw;
  height: 100vh;
  z-index: 1000 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
`;

export const CenterModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1100;
`;

export const ModalBox = styled.div`
  min-width: 400px;
  min-height: 400px;
  background: white;
  color: white;
  z-index: 1100 !important;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;

  @media (max-width: 576px) {
    width: 90vw;
    height: 70vh;
    background: white;
    color: white;
    z-index: 1100 !important;
    border-radius: 1rem;
  }
`;

export const ModalHeading = styled.div`
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  display: flex;
  justify-content: center;
`;

export const Heading = styled.span`
  margin: 0;
  padding: 1rem;
  color: #2c3e50;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
`;

export const ModalContent = styled.div`
  padding: 1rem;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;

  > div {
    margin-top: 1rem;
  }
`;

export const Flex = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
`;
