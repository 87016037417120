import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  background: var(--darkGrey);
`;

export const MainContent = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
  max-width: 1180px;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 10px;

  div {
    flex-wrap: wrap;
  }

  p {
    margin: 0;
    padding: 0;
  }
  img {
    max-height: 100px;
    width: auto;
    padding-top: 20px;
  }
  .icon {
    margin-left: 2rem;
  }

  @media (max-width: 991px) {
    div {
      margin: 0 auto;
    }
  }
`;

export const LinkContent = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flext-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  height: 100%;
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  flex-wrap: wrap;

  p {
    margin-bottom: 2rem;
    padding-top: 0.75rem;
    min-width: 300px;
    line-height: 1.5rem;
    flex-wrap: wrap;
  }

  div {
    border-top: 2px solid var(--middleGrey);
    margin-bottom: 2rem;
    padding-top: 0.75rem;
    min-width: 300px;
    margin-left: 1rem;
    line-height: 1.5rem;
    flex-wrap: wrap;
  }
  @media (max-width: 991px) {
    div {
      margin: 0 auto;
      margin-bottom: 2rem;
    }
    p {
      margin: 0 auto;
      margin-bottom: 2rem;
    }
  }
`;
