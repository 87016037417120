import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";
import { getRoomSVG } from "hooks/Rooms/roomGeneral";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

export function SVGRoomList({ inputString, bldg = null }) {
  if (
    inputString === undefined ||
    inputString === null ||
    inputString?.trim() === ""
  )
    return "";
  let cntr = 1;

  //if (bldg === null) {
  //const wrkAry = inputString.split("] -");
  //const bldgPart = wrkAry?.[0];
  //const pos = bldgPart.lastIndexOf("[");
  //bldg = bldgPart.slice(pos + 1);
  //inputString = wrkAry?.[1]?.trim() ?? inputString;
  //}

  const pairs = inputString.split("),").map((pair) => {
    let wrkBldg = bldg;
    const dspStr = pair.trim();
    let wrkStr = dspStr;

    if (wrkBldg === null) {
      const wrkAry = wrkStr.split("] -");
      const bldgPart = wrkAry?.[0];
      const pos = bldgPart.lastIndexOf("[");
      wrkBldg = bldgPart.slice(pos + 1);
      wrkStr = wrkAry?.[1]?.trim() ?? wrkStr;
    }

    const locs = wrkStr.split(":");
    let floor = "";
    let rooms = "";

    // Get beg
    if ((locs?.length ?? 1) == 1) {
      floor = "01";
      rooms = locs[0].trim();
      if (rooms == "(No Room)") rooms = "";
    } else {
      floor = locs[0].trim();
      if (floor == "" || floor == "No Floor") {
        floor = "01";
      }
      rooms = locs[1].trim();
      if (rooms == "(No Room)") rooms = "";
    }

    if (wrkStr.slice(-1) == ")" || wrkStr.slice(-1) == ":") {
      const roomList = rooms?.slice(1).slice(0, -1) ?? "NONE";

      return (
        <GetRoomsSVG
          key={cntr++}
          building={wrkBldg}
          floor={floor}
          room={roomList == "" ? "NONE" : roomList}
          detail={dspStr}
        />
      );
    } else {
      return (
        <GetRoomsSVG
          key={cntr++}
          newLine={true}
          building={wrkBldg}
          floor={floor}
          room={rooms.slice(1)}
          detail={dspStr + ")"}
        />
      );
    }
  });

  return pairs;
}

export function GetRoomsSVG({
  building,
  floor,
  room,
  detail,
  onRefresh,
  newLine = false,
}) {
  const { svgCacheContent, setSvgCacheContent, popupWindow, setPopupWindow } =
    useGlobalContext();
  const [toggle, setToggle] = useState(false);
  // const [isHovered, setIsHovered] = useState(false);
  const val =
    "Building: " + building + " / Floor: " + floor + " / Room: " + room;

  const { isLoading, refetch, isError, error } = useQuery(
    ["rooms", building, floor, room],
    () => getRoomSVG(building, floor, room),
    {
      enabled: false,
      onSuccess: (data) => {
        const status = data?.status ?? -1;
        const result = data?.data.toString() ?? "";

        if (!svgCacheContent[val] && status === 200) {
          setSvgCacheContent({ ...svgCacheContent, [val]: result });
          if (typeof onRefresh === "function") onRefresh();
        }
      },
      fetchPolicy: "cache-first",
    }
  );

  const handleClick = (event) => {
    event.preventDefault();
    if (popupWindow) {
      popupWindow.focus();
      const title = popupWindow.document.title;
      if (title === "" || val === title) {
        return;
      }
    }
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const popupWidth = Math.floor((2 / 3) * screenWidth);
    const popupHeight = Math.floor((2 / 3) * screenHeight);
    const newPopupWindow = window.open(
      "",
      "Loading...",
      `width=${popupWidth},height=${popupHeight}`
    );
    setPopupWindow(newPopupWindow);
    if (!svgCacheContent[val]) refetch();
    setToggle((toggle) => !toggle);
  };

  useEffect(() => {
    if (popupWindow) {
      popupWindow.document.getElementsByTagName("body")[0].innerHTML = "";
      popupWindow.document.write(`
      <html>
      <head>
          <style>
            body, html {
              margin: 0;
              padding: 0;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            #svg-container {
              margin-top: 5px;
              flex: 1;
              width: 100%;
              height: 100%;
              overflow: auto;
            }
            svg {
              width: 100%;
              height: -webkit-calc(100% - 31px);
              height:    -moz-calc(100% - 31px);
              height:         calc(100% - 31px);
            }
            #close-button {
              margin-top: 5px;
              margin-bottom: 5px;
            }
          </style>
        </head>
        <body>
        <div id="svg-container">
          <div id="svg">${
            isLoading
              ? "Loading..."
              : isError
              ? "Error: " + error.message
              : svgCacheContent[val]
          }</div>
          <center><button id="close-button" onclick="window.close()">Close</button></center>
        </div>
        <script>
          window.addEventListener('resize', function() {
            var svgContainer = document.getElementById('svg-container');
            svgContainer.style.width = window.innerWidth + 'px';
            svgContainer.style.height = window.innerHeight + 'px';
          });
        </script>
      </body>
    </html>
      `);
      popupWindow.document.title = val;
      popupWindow.onbeforeunload = () => {
        setPopupWindow(null); // Reset popup window state when window is closed
      };
      popupWindow.focus();
    }

    // eslint-disable-next-line
  }, [isLoading, toggle]);

  // const linkStyle = {
  //   textDecoration: "none",
  //   color: "inherit",
  //   borderBottom: "1px solid transparent",
  //   transition: "border-color 0.2s",
  // };

  // const hoverStyle = {
  //   borderBottom: "1px solid blue",
  // };

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  // };

  return (
    <a
      href="#"
      onClick={handleClick}
      title="Click to show a map of the floor.&#013; - Highlighting the room."
      className="hoverLink"
      // style={isHovered ? { ...linkStyle, ...hoverStyle } : linkStyle}
    >
      {detail === "RoomDetail" ? (
        <>
          <strong>Room Number:&nbsp;</strong>
          {room}
        </>
      ) : detail === "RoomList" ? (
        <>
          <strong>Room:&nbsp;</strong>
          {room}&nbsp;/&nbsp;{floor}
        </>
      ) : detail !== undefined && detail !== null && detail.trim() !== "" ? (
        <>
          {detail}
          {newLine ? <br /> : <></>}
        </>
      ) : (
        <>{room}</>
      )}
    </a>
  );
}
