//npm install --save react-dropzone-uploader
import FileUpload from "../FileUpload/FileUpload.jsx";

export const ExcelUpload = (props) => {
  const { backEndCall = null, title = null, defaultBatchSize = null } = props;

  return (
    <div style={{ padding: "10px 20px", textAlign: "center" }}>
      <b>{title}</b>
      <FileUpload
        backEndCall={backEndCall}
        defaultBatchSize={defaultBatchSize}
      />
    </div>
  );
};
