import styled from "styled-components";

export const Container = styled.div`
  .active {
    background-color: var(--illiniDarkBlue);
    color: white !important;
  }
`;

export const StyledEllipse = styled.div`
  display: inline-block;
  box-sizing: border-box;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  border: 2px solid var(--illiniOrange);
  color: #13294b;

  .active {
    color: white;
  }
`;

export const StyledLabel = styled.div`
  height: 48px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 42px;
  text-align: center;
`;
