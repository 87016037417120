import Ellipse from "components/Common/Ellipse/Ellipse";

const Steps = ({ activeStep, setStep }) => {
  function BuildStep(stepNumber, stepLabel) {
    let containerClass = "col-2 text-center text-uppercase align-middle";

    let activeClass = "";
    if (activeStep === stepNumber) {
      activeClass = "active";
    }
    let cursor = null;
    if (stepNumber <= activeStep) {
      cursor = "pointer";
    }

    return (
      <a
        className={containerClass}
        style={{ textAlign: "center", cursor: cursor }}
        onClick={() => {
          if (stepNumber <= activeStep) {
            setStep(stepNumber);
          }
        }}
      >
        <Ellipse number={stepNumber} className={activeClass} />
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          {stepLabel}
        </div>
      </a>
    );
  }

  return (
    <div
      className="row justify-content-center shadow p-3"
      style={{
        backgroundColor: "#efefef",
        color: "#000",
        width: "1180px",
        margin: "0 auto",
        maxWidth: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        padding: "2.5rem",
      }}
    >
      {BuildStep(1, "Access")}
      {BuildStep(2, "Keyholder(s)")}
      {BuildStep(3, "Additional Information")}
      {BuildStep(4, "Review and Submit")}
    </div>
  );
};
export default Steps;
