import styled from "styled-components";

export const TableHead = styled.thead`
  color: #fff;
  background: var(--illiniDarkBlue);
`;

export const TableRow = styled.tr`
  @keyframes fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      display: none;
    }
  }

  border: 2px solid #e4e4e4;
  line-height: 1.5rem;
`;

export const DarkRow = styled.tr`
  background: #f2f2f2;
`;

export const TableBody = styled.tbody`
  color: #13294b;

  & ${TableRow}:nth-child(even) {
    background: #f2f2f2;
  }

  & ${TableRow}:last-child > td:first-child {
    border-radius: 0 0 0 8px;
  }
`;

export const RoundLeft = styled.th`
  border-radius: 8px 0 0 0;
  padding: 15px;
`;

export const RoundRight = styled.th`
  border-radius: 0 8px 0 0;
`;

export const StyledTable = styled.table`
  border-radius: 0.5rem;
  width: 100%;
  overflow-x: scroll;

  .hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }
`;
export const TableLink = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  border: 2px solid var(--il-blue);
  border-radius: 4px;
  padding: 5px 10px;
  margin: 0.2rem;
  text-decoration: none;
  color: #13294b;
  &:hover {
    background: #13294b;
    color: #fff;
  }
`;
