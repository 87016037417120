import styled from "styled-components";

export const Wrapper = styled.header`
  border-top: 7px solid var(--illiniOrange);
  width: 100%;
  z-index: 500;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 10px;

  p{

    border-left: 2px solid black;
    height: 60px;
    padding: 12px 20px 0px 20px;
    margin-left: 20px;
}

  }
  div{
    display:flex;
    align-items:flex-end;
    height:100%;
    justify-content:left;
  }
  .center{
        flex-wrap: wrap;
    justify-content: flex-end;
    text-align: right;
        margin-bottom: 1rem;

  }

  @media(max-width:991px){
    .center{
      display:none;
    }
  }
  @media(max-width:520px){
    p{
      font-family: 'Source Sans Pro';
      font-weight: 700;
      font-size: 1rem;
      color:var(--illiniDarkBlue);
      height:32px;
      padding: 0rem 0rem 1.5rem 0.75rem;
    }
  }
`;

export const MainContent = styled.header`
  background: white;
  height: 110px;

  width: 100%;
  z-index: 10000;

  @media (max-width: 520px) {
    height: 70px;
  }
`;

export const NavContent = styled.div`
  height: 50px;
  width: 100%;
  margin: 0 auto;

  background: var(--darkGrey);

  @media (max-width: 991px) {
    display: none;
  }
`;

export const UIUCContent = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  height: 30px;

  p {
    color: var(--illiniDarkBlue);
    padding: 0 10px;
    font-weight: 700;
    font-size: 0.75rem;
    letter-spacing: 2px;
  }

  @media (max-width: 520px) {
    display: none;
  }
`;

export const LogoImage = styled.img`
  max-width: 35px;

  @media (max-width: 520px) {
    max-width: 20px;
    margin-bottom: 1.25rem;
  }
`;
