import { useState } from "react";

import { StyledButton } from "./ButtonLarge.style";

const ButtonLarge = (props) => {
  const { type = "radio", info = [], icon = true, className } = props;

  const [selected, setSelected] = useState();

  const handleClick = (e) => {
    const value = info.id;
    setSelected(!selected);
    props.onClick(value);
  };

  return (
    <StyledButton
      selected={props.selected}
      className={className}
      onClick={(e) => handleClick(e)}
    >
      {icon && props.selected ? (
        <div style={{ float: "right" }}>
          {type === "radio" ? (
            <i className="fa-regular fa-circle-check"></i>
          ) : (
            <i className="fa-regular fa-square-check"></i>
          )}
        </div>
      ) : (
        <div style={{ float: "right" }}>
          {type === "radio" ? (
            <i className="fa-regular fa-circle"></i>
          ) : (
            <i className="fa-regular fa-square"></i>
          )}
        </div>
      )}
      {info.title}
      <p>{info.description}</p>
    </StyledButton>
  );
};
export default ButtonLarge;
