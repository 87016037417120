import axios from "axios";

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_URL;

import requestInterceptor from "./requestInterceptor";

const axiosInstance = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosInstance.interceptors.request.use(requestInterceptor, (error) =>
  Promise.reject(error)
);

export default axiosInstance;
