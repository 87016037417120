import axiosInstance from "hooks/Axios/axiosClient";

// Calls below have not been reviewed

const getRoomByDept = async (department) => {
  const response = await axiosInstance.get(
    "/api/rooms/list/" +
      (department !== undefined && department !== null ? department : "")
  );

  return response;
};

const getRoom = async (id) => {
  const response = await axiosInstance.get("/api/rooms/id/" + id);

  return response;
};

const getBuildingKeys = async (id) => {
  try {
    const ary = id.split("-");
    const building = ary[0];
    const room = ary[2];

    const response = await axiosInstance.get(
      "/api/lists/getBuildingKeys/" + (building ?? "") + "/" + (room ?? "")
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

const getRoomAssigments = async (room) => {
  const response = await axiosInstance.get("/api/rooms/assignments/" + room);
  return response;
};

//APIs below this are Pending review
const getRooms = async (department, building) => {
  const response = await axiosInstance.get(
    "/api/rooms/list/" + department + "/" + building
  );

  return response;
};

const getBuildings = async (building) => {
  const response = await axiosInstance.get(
    "/api/lists/buildings" +
      (building !== "undefined" && building !== null ? "/" + building : "")
  );
  return response;
};

const getOwnerRooms = async (user) => {
  const response = await axiosInstance.get("/api/rooms/owner/" + user);
  return response;
};

const getRoomNotes = async (room) => {
  const response = await axiosInstance.get("/api/rooms/notes/" + room);
  return response;
};

const createRoomNote = async (json) => {
  const response = await axiosInstance.post("/api/rooms/note/", { json });
  return response;
};

const deleteRoomNote = async (id) => {
  const response = await axiosInstance.delete(`/api/rooms/note/${id}`);
  return response;
};

export {
  getRoomByDept,
  getRoom,
  getBuildingKeys,
  getRooms,
  getBuildings,
  getOwnerRooms,
  getRoomAssigments,
  getRoomNotes,
  createRoomNote,
  deleteRoomNote,
};
