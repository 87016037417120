import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Layout from "components/Common/Layout/Layout";

import Home from "components/Home/Home";
import Login from "components/Login/Login";
import Logout from "components/Logout/Logout";

import Keys from "../components/Keys/Keys/Keys";
import KeyDetail from "../components/Keys/KeyDetails/KeyDetail";
import Approvals from "../components/Approvals/Approvals/Approvals";
// import MyTasks from "components/MyTasks/MyTasks/MyTasks";

import RequestKeys from "components/RequestAKey/KeyRequests/RequestKeys/RequestKeys";
// import MyRequests from "components/MyRequests/MyRequests/MyRequests";
// import UserRequests from "../components/RequestAKey/Requests/Requests";

import Rooms from "components/Rooms/Rooms/Rooms";
import GetRoomDetail from "components/Rooms/getRoomDetail/getRoomDetail";

import Uploads from "../components/Uploads/Uploads/Uploads";
import { useIsAuthenticated } from "@azure/msal-react";
import { getRoles } from "hooks/User/userRoles";

const Page404 = () => <h1>Four:oh:four</h1>;

const AppRoutes = () => {
  const [roles, setRoles] = useState(null);
  const [allAccess, setAllAccess] = useState(true);
  const [keysAccess, setKeysAccess] = useState(true);
  const [roomsAccess, setRoomsAccess] = useState(true);
  const [adminAccess, setAdminAccess] = useState(true);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated && roles === null) {
      getRoles().then((response) => {
        setRoles(response.data);
      });
    }

    if (roles) {
      setAllAccess(roles.includes(1));
      setKeysAccess(roles?.includes(2) || roles?.includes(1));
      setRoomsAccess(roles.includes(3) || roles.includes(1));
      setAdminAccess(roles.includes(5) || roles.includes(1));
    }
  }, [isAuthenticated, roles]);

  return (
    <Routes>
      {isAuthenticated ? (
        <>
          <Route element={<Layout title="Key Request Management System" />}>
            <Route path="/" element={<Home />} />
          </Route>
          {keysAccess && (
            <Route element={<Layout title="Keys" />}>
              <Route path="keys" element={<Keys />} />
              <Route path="keys/:id" element={<KeyDetail />} />
            </Route>
          )}

          <Route element={<Layout title="Key Request Approvals" />}>
            <Route path="approvals" element={<Approvals />} />
          </Route>
          {/* <Route element={<Layout title="My Tasks" />}>
              <Route path="mytasks" element={<MyTasks />} />
            </Route> */}
          {/* <Route element={<Layout title="My Requests" />}>
              <Route path="/myrequests" element={<MyRequests />} />
            </Route> */}

          {(allAccess || roomsAccess || keysAccess || adminAccess) && (
            <Route element={<Layout title="Uploads" />}>
              <Route exact path="uploads" element={<Uploads />} />
            </Route>
          )}

          <Route element={<Layout title="Request Keys" />}>
            <Route path="/requestkeys" element={<RequestKeys />} />
          </Route>

          {roomsAccess && (
            <Route element={<Layout title="Rooms" />}>
              <Route path="rooms" element={<Rooms />} />
              <Route path="rooms/:id" element={<GetRoomDetail />} />
            </Route>
          )}
          <Route element={<Layout title="Logout" />}>
            <Route path="/logout" element={<Logout />} />
          </Route>
          {/* if route doesn't exist, send user to homepage with 404page prop */}
          <Route
            path="*"
            element={<Navigate to="/" state={{ missingPage: true }} />}
          />
        </>
      ) : (
        <Route element={<Layout title="Login" />}>
          <Route path="*" element={<Login />} />
        </Route>
      )}

      <Route element={<Layout title="Error" />}>
        <Route element={Page404} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
