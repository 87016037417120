import React from "react";
import { useEffect, useState } from "react";
import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";
import {
  getRemovableBuildingHeader,
  getRemoveableBuildingRows,
} from "hooks/Buildings/buildingsTableFunctions";
import { StyledTable } from "components/Common/Styles/TableStyles";

const BuildingsSelected = () => {
  const { buildingsSelected, setBuildingsSelected } = useGlobalContext();

  const [rows, setRows] = useState(null);
  const [header, setHeader] = useState(null);

  useEffect(() => {
    setRows(getRemoveableBuildingRows(buildingsSelected, setBuildingsSelected));
    setHeader(getRemovableBuildingHeader());
  }, [buildingsSelected]);

  return (
    <>
      {buildingsSelected.length === 0 ? (
        <p
          style={{
            textAlign: "center",
            margin: "0 auto",
            marginBottom: "1rem",
            color: "var(--illiniDarkBlue)",
          }}
        >
          No buildings selected
        </p>
      ) : (
        <StyledTable>
          {header}
          <tbody>{rows}</tbody>
        </StyledTable>
      )}
    </>
  );
};

export default BuildingsSelected;
