import React, { useEffect } from "react";
import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";
import { useQuery } from "react-query";
import Select from "components/Common/Select/Select";
import { getEAC } from "hooks/Key/keyData";

export function GetEACDropDown({
  onSelectItem, //function that gets the selected value
  setFirstValue, //set the default value
  onRefresh, //function called after data is loaded
  totalCountCallback, //total items in dropdown
  defaultOption, //Set the default based on the text provided
  defaultValue, //set the default
}) {
  const { electronicAccessControl, setElectronicAccessControl } =
    useGlobalContext();
  const key = "";

  const handleSelectEAC = (item) => {
    if (typeof onSelectItem === "function") onSelectItem(item);
  };

  const { data, isLoading, refetch, isError, error } = useQuery(
    ["EAC"],
    () => getEAC(),
    {
      select: (data) => {
        const eac = data?.data.results ?? [{ id: 1, name: "None" }];

        return eac;
      },
      enabled: false,
      onSuccess: (data) => {
        if (!electronicAccessControl[key]) {
          setElectronicAccessControl({
            ...electronicAccessControl,
            [key]: data,
          });
          if (typeof onRefresh === "function") onRefresh();
          if (typeof totalCountCallback === "function")
            totalCountCallback(data?.length ?? 0);
        }
      },
      fetchPolicy: "cache-first",
    }
  );

  useEffect(() => {
    if (!isLoading && !electronicAccessControl[key]) {
      if (!electronicAccessControl[key]) refetch();
    }
  }, [isLoading, electronicAccessControl, refetch]);

  return (
    <>
      {isLoading ? (
        <Select
          label="Electronic Access Control"
          optionsheader="-- Loading --"
        />
      ) : isError ? (
        <Select
          label="Electronic Access Control"
          optionsheader={error.message}
        />
      ) : (
        <Select
          label="Electronic Access Control"
          defaultValue={defaultValue}
          defaultOption={defaultOption}
          optionsheader={setFirstValue ? "" : "-- Select an EAC --"}
          width="100%"
          options={electronicAccessControl[key] ?? data}
          onChange={(e) => handleSelectEAC(e)}
        />
      )}
    </>
  );
}
