export const nameFormat = (name) => {
  const nameArray = name.split(" ");
  let lastName = nameArray[0];
  //remove , from lastName
  if (lastName.includes(",")) {
    lastName = lastName.substring(0, lastName.length - 1);
  }
  const firstName = nameArray[1];
  return `${firstName} ${lastName}`;
};
