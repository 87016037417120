import styled from "styled-components";

export const Container = styled.div`
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 1.25rem;

  color: "var(--illiniDarkBlue)";

  div:hover {
    cursor: pointer;
  }
`;

export const Container2 = styled.div`
  width: 1180px;
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;
