import { TitleH2 } from "./Title.styles";

const Title = ({ ...props }) => {
  return (
    <div className="module_header">
      <div className="module_controls" style={{ textAlign: "left" }}>
        <TitleH2>{props.title}</TitleH2>
      </div>
    </div>
  );
};

export default Title;
