import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "react-query";
import { getICardDataByNetID } from "../../../hooks/Key/keyData";
import {
  InputContainer,
  Dropdown,
  DropdownItem,
  Input,
} from "./LocalNetID.styles"; // For dropdown styling

const LocalNetID = ({
  onSelectValue,
  displayValue = "",
  lookupValueNetID = "",
  readOnly = false,
  touched,
}) => {
  const [value, setValue] = useState(displayValue);
  const [savedValue, setSavedValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const endWith = useRef("true");
  const showValue = useRef(displayValue);

  // Use useQuery to fetch and cache the suggestions
  const { data: suggestions = [], isFetching } = useQuery(
    ["suggestions", value], // Query key includes the input to cache per input
    () => getICardDataByNetID(savedValue, endWith.current),
    {
      enabled: (savedValue?.length ?? 0) >= 3 && showDropdown, // Only fetch when input has at least 3 characters
      staleTime: 5 * 60 * 1000, // Cache results for 5 minutes
      cacheTime: 10 * 60 * 1000, // cache for 2 hours
      keepPreviousData: true, // Keep previous data while fetching
      refetchOnWindowFocus: false,
    }
  );

  //Set the saved value
  useEffect(() => {
    if (lookupValueNetID !== "") {
      if (readOnly) {
        endWith.current = "false";
      }

      setSavedValue(lookupValueNetID);
    }
  }, []);

  useEffect(() => {
    if (readOnly) {
      const name = suggestions?.data?.results?.[0] ?? "";

      setValue(getName(name));
    }
  }, [suggestions]);

  const getName = (item) => {
    return (
      (item?.networkID ?? "") +
      " (" +
      toTitleCase(item?.lastName ?? "") +
      ", " +
      toTitleCase(item?.firstName ?? "") +
      ")"
    );
  };

  // Handle suggestion click
  const handleSuggestionClick = (item) => {
    const name = getName(item);

    showValue.current = name; // Set the input to the selected suggestion
    setValue(name);
    setShowDropdown(false); // Hide the dropdown when an option is selected

    if (typeof onSelectValue === "function") {
      onSelectValue(item.UIN); // Pass the selected suggestion back to the parent component
    }
  };

  // Show dropdown when input field is focused or clicked
  const handleFocus = () => {
    if (!readOnly) {
      setValue(savedValue);

      if ((savedValue?.length ?? 0) >= 3) {
        setShowDropdown(true); // Only show dropdown if input has 3 or more characters
      }
    }
  };

  const handleBlur = (e) => {
    setValue(showValue.current);

    // Give time for the click event on dropdown items to register before closing the dropdown
    setTimeout(() => {
      setShowDropdown(false);
    }, 100);
  };

  const toTitleCase = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const suggetsionList = suggestions?.data?.results ?? [];

  return (
    <InputContainer>
      <Input
        type="text"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setSavedValue(e.target.value);
          if (e.target.value.length >= 3) {
            setShowDropdown(!readOnly);
          } else {
            setShowDropdown(false);
          }
        }}
        onFocus={handleFocus} // Show the dropdown again on focus
        onBlur={handleBlur} // Hide the dropdown when focus is lost
        readOnly={readOnly}
        placeholder="Enter Net Id..."
        touched={touched}
      />
      {isFetching && !readOnly && <div>Loading...</div>}
      {showDropdown &&
        (value?.length ?? 0) >= 3 &&
        (suggetsionList?.length ?? 0) > 0 && (
          <Dropdown>
            {suggetsionList.map((item, index) => (
              <DropdownItem
                key={index}
                onMouseDown={() => handleSuggestionClick(item)} // Prevent onBlur from hiding dropdown prematurely
              >
                {getName(item)}
              </DropdownItem>
            ))}
          </Dropdown>
        )}
    </InputContainer>
  );
};

export default LocalNetID;
