import { useQuery } from "react-query";
import { getUser } from "hooks/User/userData";
import { useState, Fragment } from "react";

import ReactLoading from "react-loading";

const Profile = (props) => {
  const { user = null, value = "full" } = props;

  const [nameList, setNameList] = useState(null);
  const [userVal, setUserVal] = useState(null);

  const { isLoading, isError, error } = useQuery(
    ["user", user],
    () => getUser(user),
    {
      onSuccess: (data) => {
        setNameList(data);
        setUserVal(user);
      },
      enabled: nameList === null || userVal !== user,
      refetchOnWindowFocus: false,
    }
  );

  let rows;
  if (isLoading) {
    rows = (
      <ReactLoading type="bubbles" color="#000" height="80px" width="80px" />
    );
  } else if (isError) {
    rows = error.message;
  } else {
    const { names } = nameList?.data?.person || {};

    rows = names?.map((name, index) => {
      if (name.type === "LEGAL") {
        switch (value) {
          case "full":
            return (
              <Fragment key={index}>
                {name.firstName} {name.middleName}{" "}
                <strong>{name.lastName}</strong>
              </Fragment>
            );

          case "first":
            return <Fragment key={index}>{name.firstName}</Fragment>;

          case "middle":
            return <Fragment key={index}>{name.middleName}</Fragment>;

          case "last":
            return <Fragment key={index}>{name.lastName}</Fragment>;
          default:
            return <Fragment key={index}></Fragment>;
        }
      } else {
        return <Fragment key={index}></Fragment>;
      }
    });
  }

  return rows;
};
export default Profile;
