import React, { useState, useEffect } from "react";
import { Container } from "./Links.styles";
import Card from "../../Common/Card/Card";
import Button from "../../Common/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDoorOpen,
  faUser,
  faKey,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { getRoles } from "hooks/User/userRoles";
import { useNavigate } from "react-router-dom";

const Links = () => {
  const [roles, setRoles] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (roles === null) {
      getRoles().then((response) => {
        if (response.data) {
          setRoles(response.data);
        }
      });
    }
  });

  const keysAccess = roles?.includes(2) || roles?.includes(1);
  const roomsAccess = roles?.includes(3) || roles?.includes(1);

  const rightIcon = <FontAwesomeIcon icon={faAngleRight} />;
  let Link = [];

  if (keysAccess) {
    Link.push({
      Link: "Keys",
      to: "/keys",
      icon: <FontAwesomeIcon icon={faKey} />,
    });
  }
  if (roomsAccess) {
    Link.push({
      Link: "Rooms",
      to: "/rooms",
      icon: <FontAwesomeIcon icon={faDoorOpen} />,
    });
  }
  Link.push({
    Link: "Key Request",
    to: "/requestkeys",
    icon: <FontAwesomeIcon icon={faUser} />,
  });

  const onClick = (link) => {
    navigate(link);
  };

  const LinkButtons = Link.map((link, index) => {
    return (
      <Button
        key={index}
        onClick={() => {
          onClick(link.to);
        }}
        label={
          <>
            {link.icon}
            <span>{link.Link}</span>
            {rightIcon}
          </>
        }
      ></Button>
    );
  });
  return (
    <Container>
      <Card
        padding="1.5rem"
        width="100%"
        content={
          <>
            <p className="tertiaryTitle">Quick Access Menu</p>
            {LinkButtons}
          </>
        }
      ></Card>
    </Container>
  );
};

export default Links;
