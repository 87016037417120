import styled from "styled-components";

export const Green = styled.div`
  padding: 10px;
  background: #ccffff;
`;

export const Pink = styled.div`
  padding: 10px;
  background: #ffcccc;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const FlexContainer = styled.div`
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
`;

export const CenterStyle = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const HalfWidth = styled.div`
  width: 50%;
  margin-bottom: 2rem;
  padding-right: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  @media (max-width: 520px) {
    width: 100%;
  }
`;

export const HalfWidth2 = styled.div`
  width: 50%;
  margin-bottom: 2rem;
  align-content: flex-end;

  @media (max-width: 520px) {
    width: 100%;
  }
`;

export const FullWidth = styled.div`
  width: 100%;
`;

export const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`;
