import { LogLevel } from "@azure/msal-browser";

const TENANT_ID = process.env.REACT_APP_TENANT_ID;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

export const config = {
  clientId: CLIENT_ID,
  authority: `https://login.microsoftonline.com/${TENANT_ID}`,
  redirectUri: "/",
  postLogoutRedirectUri: "/",
  scopes: [`api://${CLIENT_ID}/access_as_user`],
  apiScopes: [`api://${CLIENT_ID}/.default`],
};

export const msalConfig = {
  auth: {
    ...config,
    clientCapabilities: ["CP1"],
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPII) => {},
      logLevel: LogLevel.Warning,
    },
  },
};

// const useMsalInstance = () => {
//   const [msalInstance, setMsalInstance] = useState(null);

//   useEffect(() => {
//     const instance = new PublicClientApplication({
//       auth: {
//         ...config,
//         clientCapabilities: ["CP1"],
//       },
//       cache: {
//         cacheLocation: "sessionStorage",
//         storeAuthStateInCookie: false,
//       },
//       system: {
//         loggerOptions: {
//           loggerCallback: (level, message, containsPII) => {},
//           logLevel: LogLevel.Warning,
//         },
//       },
//     });

//     instance.addEventCallback((event) => {
//       if (event.eventType === EventType.LOGIN_SUCCESS) {
//         instance.setActiveAccount(event.payload.account);
//       }
//     });

//     setMsalInstance(instance);
//   }, []);

//   const login = useCallback(() => {
//     if (!msalInstance) return null;

//     msalInstance.loginRedirect({
//       scopes: config.scopes,
//     });
//   }, [msalInstance]);

//   const generateBearer = useCallback(async () => {
//     if (msalInstance) {
//       try {
//         const response = await msalInstance.acquireTokenSilent({
//           scopes: config.scopes,
//           claims: sessionStorage.getItem("claimsChallenge")
//             ? window.atob(sessionStorage.getItem("claimsChallenge"))
//             : undefined,
//         });
//         return response.accessToken;
//       } catch (error) {
//         console.log("GenerateBearer ERROR: " + error);
//       }
//     }
//   }, [msalInstance]);

//   const refreshToken = useCallback(async () => {
//     if (!msalInstance) return null;

//     const response = await msalInstance.acquireTokenSilent({
//       ...config,
//       scopes: ["user.read"],
//       account: msalInstance.getActiveAccount(),
//       claims: sessionStorage.getItem("claimsChallenge")
//         ? window.atob(sessionStorage.getItem("claimsChallenge"))
//         : undefined,
//     });

//     return response.accessToken;
//   }, [msalInstance]);

//   return {
//     msalInstance,
//     login,
//     generateBearer,
//     refreshToken,
//   };
// };

// export default useMsalInstance;
