import { useQuery } from "react-query";
import { getUserICard } from "hooks/User/userData";
import { useState, Fragment, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Container } from "./icard.styles";

import ReactLoading from "react-loading";

const ICard = (props) => {
  const initDispatched = useRef(false);

  const { user = "", value = "full", imageWidth = "50px" } = props;

  const [icardList, setICardList] = useState(null);

  const { data, isLoading, isError, error } = useQuery(
    ["image", user],
    () => getUserICard(user),
    {
      onSuccess: (data) => {
        setICardList(data);
      },
      refetchOnWindowFocus: false,
      staleTime: 60 * (60 * 1000), // 1 hour
      cacheTime: 120 * (60 * 1000), // 2 hours
    }
  );

  useEffect(() => {
    if (data && !initDispatched.current && icardList === null) {
      initDispatched.current = true;
      setICardList(data);
    }
    // eslint-disable-next-line
  }, [data]);

  let rows;
  if (isLoading) {
    rows = (
      <ReactLoading type="balls" color="#ffffff" height={667} width={375} />
    );
  } else if (isError) {
    rows = error.message;
  } else {
    rows = icardList?.data?.results?.map((image, index) => {
      switch (value) {
        case "full":
          return (
            <span key={index}>
              {capitalize(image.UINData[0].Person[0].FirstName)}{" "}
              {capitalize(image.UINData[0].Person[0].MiddleName)}{" "}
              {capitalize(image.UINData[0].Person[0].LastName)}
            </span>
          );

        case "first":
          return (
            <Fragment key={index}>
              {capitalize(image.UINData[0].Person[0].FirstName)}
            </Fragment>
          );

        case "middle":
          return (
            <Fragment key={index}>
              {capitalize(image.UINData[0].Person[0].MiddleName)}
            </Fragment>
          );

        case "last":
          return (
            <Fragment key={index}>
              {capitalize(image.UINData[0].Person[0].LastName)}
            </Fragment>
          );

        case "netid":
          return (
            <Fragment key={index}>
              {capitalize(image.UINData[0].Person[0].NetID)}
            </Fragment>
          );

        case "cardnumbers":
          return (
            <span key={index}>
              <strong>Card Number: </strong>
              {image.UINData[0].IDCard !== undefined
                ? image.UINData[0]?.IDCard[0].CardNumber
                : ""}
              <br />
              <strong>Sequence: </strong>{" "}
              {image.UINData[0].IDCard !== undefined
                ? image.UINData[0]?.IDCard[0].CardSequence
                : ""}
              <br />
            </span>
          );

        case "carddates":
          return (
            <span key={index}>
              <strong>Card Begins: </strong>
              {image.UINData[0].IDCard !== undefined
                ? image.UINData[0]?.IDCard[0].CardStartDate
                : ""}
              <br />
              <strong>Card Expires: </strong>
              {image.UINData[0].IDCard !== undefined
                ? image.UINData[0]?.IDCard[0].CardExpireDate
                : ""}
              <br />
            </span>
          );

        case "badge":
          return (
            <span key={index}>
              <strong>Badge Code: </strong>
              {image.UINData[0].IDCard !== undefined
                ? image.UINData[0]?.IDCard[0].BadgeTypeCode
                : ""}
              <br />
              <strong>Badge Type: </strong>
              {image.UINData[0].IDCard !== undefined
                ? image.UINData[0]?.IDCard[0].BadgeTypeDesc
                : ""}
              <br />
            </span>
          );

        case "image":
          let img = image.UINData[0].IDPhoto[0].PhotoJPGBase64;
          let person = image.UINData[0].Person[0];

          return (
            <Container>
              {img ? (
                <img
                  key={index}
                  src={
                    (typeof img === "string" || img instanceof String) &&
                    img.length > 1
                      ? `data:image/jpg;base64,${img}`
                      : process.env.PUBLIC_URL + "/data/images.jpg"
                  }
                  alt={`${person.FirstName} ${person.MiddleName} ${person.LastName}`}
                  style={{ width: `${imageWidth}` }}
                />
              ) : (
                <>
                  <FontAwesomeIcon
                    style={{
                      color: "#13294B",
                      height: "2.5rem",
                      marginBottom: "1rem",
                    }}
                    alt="no image found"
                    icon={faUser}
                  />
                  <p>No Image Found</p>
                </>
              )}
            </Container>
          );

        default:
          return <span key={index}></span>;
      }
    });
  }

  return rows;
};
export default ICard;

function capitalize(str) {
  return str && str[0].toUpperCase() + str.slice(1).toLowerCase();
}
