import {
  TableRow,
  TableHead,
  RoundLeft,
  RoundRight,
} from "../../components/Common/Styles/TableStyles";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";

import { GetRoomsSVG } from "components/Context/getRoomsSVG/getRoomsSVG";
import Button from "components/Common/Button/Button";

export function getRoomRows(
  rows,
  isLoading,
  isError,
  error,
  results,
  saveResults,
  saveBuilding,
  building,
  saveSearch,
  search,
  saveSrch
) {
  let srch = 0;

  if (isLoading) {
    rows.current = (
      <TableRow key={1}>
        <td style={{ textAlign: "center" }} colSpan="6">
          Loading...
          <br />
          <ReactLoading type="bubbles" color="#000000" height="300" />
        </td>
      </TableRow>
    );
  } else if (isError) {
    rows.current = (
      <TableRow key={1}>
        <td>{error.message}</td>
      </TableRow>
    );
  } else {
    try {
      if (
        saveResults.current === results &&
        saveBuilding.current === building &&
        saveSearch.current === search
      ) {
        srch = -1;
      } else {
        saveResults.current = results;
        saveBuilding.current = building;
        saveSearch.current = search;

        let filter = building?.toString() ?? "";

        if (
          filter !== "" &&
          results !== undefined &&
          !results.some((item) => item.bl_id.includes(filter))
        )
          filter = "";

        rows.current = results
          ?.filter((n) => {
            if (filter !== "") if (n.bl_id !== filter) return false;

            if (search !== "" || filter !== "") {
              srch = srch ?? 0;

              if (n.rm_id?.toLowerCase().includes(search?.toLowerCase()))
                srch++;
              else return false;
            } else srch = results?.length;

            return n;
          })
          .map((room, index) => (
            <TableRow key={room.rm_id + room.bl_id + room.fl_id + room.dp_id}>
              <td>{index + 1}</td>
              <td>
                <GetRoomsSVG
                  building={room.bl_id}
                  floor={room.fl_id}
                  room={room.rm_id}
                  detail="RoomList"
                />
                <br />
                <strong>Type:</strong> {room.use1} / {room.on_off_campus}
                <br />
                <strong>Sq Ft:</strong> {room.rm_area}
              </td>
              <td>
                {room.bl_name} ({room.bl_id}) ({room.banner_name_abrev})
              </td>
              <td>
                {room.dp_name}
                <br />({room.dp_id})
              </td>
              <td>
                <strong>Use Category:</strong> {room.cat_description} (
                {room.rm_cat})
                <br />
                <strong>Actual Room Use:</strong> {room.type_description} (
                {room.rm_type})
                <br />
                <strong>Percentage:</strong>{" "}
                {parseFloat(room?.pct_space ?? 0).toFixed(2)}%
              </td>
              <td>
                <Link to={room.bl_id + "-" + room.fl_id + "-" + room.rm_id}>
                  <Button label="View" />
                </Link>
              </td>
            </TableRow>
          ));
      }
    } catch (error) {
      console.error("ERROR (getRoomListTable) " + error.message);
      return (
        <TableRow key={1}>
          <td>{error.message}</td>
        </TableRow>
      );
    }
  }

  if (srch > -1) saveSrch.current = srch;
}

export function getRoomHeader() {
  return (
    <TableHead>
      <tr>
        <RoundLeft scope="col" width="2%">
          n
        </RoundLeft>
        <th scope="col">
          Room / Floor
          <br />
          Type / Campus
          <br />
          Sq Ft
        </th>
        <th scope="col">
          Building
          <br />
          (Building Code)
          <br />
          (Abbr Code)
        </th>
        <th scope="col">
          Unit
          <br />
          (Dept Code)
          <br />
        </th>
        <th scope="col">
          Use Category
          <br />
          Actual Room Use
          <br />
          Percentage
        </th>
        <RoundRight scope="col">Action</RoundRight>
      </tr>
    </TableHead>
  );
}

export function getSelectableRoomRows(
  rows,
  isLoading,
  isError,
  error,
  results,
  saveResults,
  saveBuilding,
  building,
  saveSearch,
  search,
  saveSrch,
  roomsSelected,
  setRoomsSelected,
  buildingsSelected,
  setBuildingsSelected,
  keysSelected,
  setKeysSelected,
  offset
) {
  let srch = 0;

  const addRoom = (e, room, index) => {
    e.target.disabled = true;
    e.target.innerHTML = "Added";

    room.index = index;

    setRoomsSelected((roomsSelected) => [...roomsSelected, room]);
  };

  if (isLoading) {
    rows.current = (
      <TableRow key={1}>
        <td style={{ textAlign: "center" }} colSpan="6">
          Loading...
          <br />
          <ReactLoading type="bubbles" color="#000000" height="300" />
        </td>
      </TableRow>
    );
  } else if (isError) {
    rows.current = (
      <TableRow key={1}>
        <td>{error.message}</td>
      </TableRow>
    );
  } else {
    try {
      if (
        saveResults?.current === results &&
        saveBuilding?.current === building &&
        saveSearch?.current === search
      ) {
        srch = -1;
      } else {
        saveResults.current = results;
        saveBuilding.current = building;
        saveSearch.current = search;

        let filter = building?.toString() ?? "";

        if (
          filter !== "" &&
          results !== undefined &&
          !results.some((item) => item.bl_id.includes(filter))
        )
          filter = "";

        /*
        //filter out rooms that are already in roomsSelected
        if (roomsSelected.length > 0) {
          results = results.filter((room) => {
            return !roomsSelected.some((item) => {
              return item === room;
            });
          });
        }
        */

        rows.current = results
          ?.filter((n) => {
            if (filter !== "") if (n.bl_id !== filter) return false;

            if (search !== "" || filter !== "") {
              srch = srch ?? 0;

              if (n.rm_id?.toLowerCase().includes(search?.toLowerCase()))
                srch++;
              else return false;
            } else srch = results?.length;

            //if room is already in roomsSelected, filter it out

            return n;
          })
          .map((room, index) => (
            <TableRow key={room.rm_id + room.bl_id + room.fl_id + room.dp_id}>
              <td>{index + 1}</td>
              <td>
                <GetRoomsSVG
                  building={room.bl_id}
                  floor={room.fl_id}
                  room={room.rm_id}
                  detail="RoomList"
                />
                <br />
                <strong>Type:</strong> {room.use1} / {room.on_off_campus}
                <br />
                <strong>Sq Ft:</strong> {room.rm_area}
              </td>
              <td>
                {room.bl_name} ({room.bl_id}) ({room.banner_name_abrev})
              </td>
              <td>
                <strong>Use Category:</strong> {room.cat_description} (
                {room.rm_cat})
                <br />
                <strong>Actual Room Use:</strong> {room.type_description} (
                {room.rm_type})
                <br />
                <strong>Percentage:</strong>{" "}
                {parseFloat(room?.pct_space ?? 0).toFixed(2)}%
              </td>
              <td>
                <Button
                  id={room.rm_id + room.bl_id + room.fl_id + room.dp_id}
                  onClick={(e) => addRoom(e, room, index + 1)}
                  label="Add"
                />
              </td>
            </TableRow>
          ));
      }
    } catch (error) {
      console.error("ERROR (getRoomListTable) " + error.message);
      return (
        <TableRow key={1}>
          <td>{error.message}</td>
        </TableRow>
      );
    }
  }

  if (srch > -1) saveSrch.current = srch;
}

export function getSelectableRoomHeader() {
  return (
    <TableHead>
      <tr>
        <RoundLeft scope="col" width="2%">
          n
        </RoundLeft>
        <th scope="col">
          Room / Floor
          <br />
          Type / Campus
          <br />
          Sq Ft
        </th>
        <th scope="col">
          Building
          <br />
          (Building Code)
          <br />
          (Abbr Code)
        </th>
        <th scope="col">Use Category</th>
        <RoundRight scope="col">Add</RoundRight>
      </tr>
    </TableHead>
  );
}

export function getRemovableRoomHeader() {
  return (
    <TableHead>
      <tr>
        <RoundLeft scope="col" width="2%">
          n
        </RoundLeft>
        <th scope="col">
          Room / Floor
          <br />
          Type / Campus
          <br />
          Sq Ft
        </th>
        <th scope="col">
          Building
          <br />
          (Building Code)
          <br />
          (Abbr Code)
        </th>
        <th scope="col">Use Category</th>
        <RoundRight scope="col">Remove</RoundRight>
      </tr>
    </TableHead>
  );
}

export function getRemoveableRoomRows(
  roomsSelected, // Add roomsSelected as a parameter
  setRoomsSelected // Add setRoomsSelected as a parameter
) {
  const onChange = (room) => {
    //remove row from rooms selected
    setRoomsSelected(
      roomsSelected.filter((room1) => {
        return room1 !== room;
      })
    );

    //enable button
    const button = document.getElementById(
      room.rm_id + room.bl_id + room.fl_id + room.dp_id
    );
    if (button) {
      button.disabled = false;
      button.innerHTML = "Add";
    }
  };

  const rows = roomsSelected?.map((room, index) => (
    <TableRow key={room.rm_id + room.bl_id + room.fl_id + room.dp_id}>
      <td>{index + 1}</td>
      <td>
        <GetRoomsSVG
          building={room.bl_id}
          floor={room.fl_id}
          room={room.rm_id}
          detail="RoomList"
        />
        <br />
        <strong>Type:</strong> {room.use1} / {room.on_off_campus}
        <br />
        <strong>Sq Ft:</strong> {room.rm_area}
      </td>
      <td>
        {room.bl_name} ({room.bl_id}) ({room.banner_name_abrev})
      </td>
      <td>
        <strong>Use Category:</strong> {room.cat_description} ({room.rm_cat})
        <br />
        <strong>Actual Room Use:</strong> {room.type_description} (
        {room.rm_type})
        <br />
        <strong>Percentage:</strong>{" "}
        {parseFloat(room?.pct_space ?? 0).toFixed(2)}%
      </td>
      <td>
        <Button onClick={() => onChange(room)} label="Remove" />
      </td>
    </TableRow>
  ));

  return rows;
}
