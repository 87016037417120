import styled from "styled-components";

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 10px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #13294b;
    background-color: #13294b;
    color: #fff;
    border-color: #13294b;
  }
`;

export const FlexSpace = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
