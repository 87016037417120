import { Outlet } from "react-router-dom";

import Header from "../Header/Header";
import Footer from "components/Common/Footer/Footer";
import Title from "../Title/Title";
import Menu from "../Menu/Menu";
import { GetUser } from "components/Context/getUser/getUser";

import { useGlobalContext } from "../GlobalContext/GlobalContext";

const Layout = ({ ...props }) => {
  const { userName } = useGlobalContext();

  if (userName === undefined || userName === null || userName === "") {
    GetUser();
  }

  return (
    <div className="mainWrapper">
      <Header />
      <Menu />
      {props.noBreadcrumb ? null : (
        <div className="breadcrumbBar">
          <Title title={props.title} />
        </div>
      )}
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
