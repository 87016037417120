import moment from "moment";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import {
  StyledTable,
  TableRow,
  TableHead,
  TableBody,
  RoundLeft,
  RoundRight,
} from "../Styles/TableStyles";
import Button from "components/Common/Button/Button";
import ReactLoading from "react-loading";
import { execNote } from "hooks/Key/keyData";
import { GetNSTDropDown } from "components/Context/getNSTDropDown/getNSTDropDown";
import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";
import { GetUser } from "components/Context/getUser/getUser";
import Profile from "components/Common/Profile/Profile";
import Input from "../Input/Input";
import Row from "components/Common/TableRow/TableRow";
import { Modal } from "../Modal/Modal";
import { FlexSpace } from "./useNotes.style";

const Notes = (props) => {
  const { id = "", roomId = null, keyId = null, unitId = null } = props; // remove the ID and make this work with room or key Ids

  const [noteAction, setNoteAction] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [currentNote, setCurrentNote] = useState("");
  const [showModal, setShowModal] = useState(false);

  const { userName } = useGlobalContext();

  const queryClient = useQueryClient();

  //JSON object for creating a note
  const noteWrite = {
    action: "select",
    uin: null,
    unitId: unitId, // for security check we will use this optional parm
    datas: {
      id: null,
      shareId: null,
      note: null,
      roomId: roomId,
      keyCode: keyId,
    },
  };

  //Prepare current date and time for display
  const date = new Date();

  const optionsTime = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const timeDisplay = new Intl.DateTimeFormat("en-US", optionsTime).format(
    date
  );

  const optionsDate = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  const dateDisplay = new Intl.DateTimeFormat("en-US", optionsDate).format(
    date
  );

  //Call notes API to get the notes data
  const { isLoading, isError, error, data } = useQuery(
    ["notes", id],
    () => execNote(noteWrite),
    {
      onError: (error) => {
        console.log("error: (notes load)", error);
        setDisabled(false);
      },
    }
  );

  //Perform note updates
  const updateNote = useMutation({
    mutationFn: (noteWrite) => {
      return execNote(noteWrite);
    },
    onSuccess: (data) => {
      const result = data.data.results;
      if (result?.length ?? 0 >= 1) {
        alert(result[0]?.message ?? "Update processed");
        setCurrentNote("");
        noteWrite.datas.note = "";
        setDisabled(false);
        setNoteAction("cancelNotes");
      }

      queryClient.invalidateQueries(["notes", id]);
    },
    onError: (error) => {
      console.log("error: (updateNote)", error);
      setDisabled(false);
    },
  });

  //General callback functions
  const handleSelectNST = (item) => {
    noteWrite.datas.shareId = item;
  };

  const handleInputText = (e) => {
    setCurrentNote(e.target.value.trim());
  };

  const handleNotesCancel = () => {
    setNoteAction("cancelNotes");
  };

  const handleNoteDelete = (action, id) => {
    if (action === "confirm") {
      setDisabled(true);
      noteWrite.action = "delete";
      noteWrite.datas.id = id;
      updateNote.mutate(noteWrite);
    }
  };

  const handleNotesSave = () => {
    if (currentNote === null || currentNote === "") {
      alert("Error: the note cannot be blank");
      const element = document.getElementById("noteArea");

      if (element) {
        element.setAttribute("style", "border:2px solid red");
        element.focus;
      }
    } else {
      setDisabled(true);
      setNoteAction("saveNotes");
      noteWrite.action = "insert";
      noteWrite.datas.note = currentNote;
      updateNote.mutate(noteWrite);

      setShowModal(false);
    }
  };

  //Build note rows
  let rows;
  if (isLoading) {
    rows = (
      <TableRow>
        <td style={{ textAlign: "center" }} colSpan="6">
          Loading
          <br />
          <ReactLoading type="bubbles" color="#000000" height="300" />
        </td>
      </TableRow>
    );
  } else if (isError) {
    rows = (
      <TableRow>
        <td>{error.message}</td>
      </TableRow>
    );
  } else {
    let cntr = 0;

    rows = data?.data?.result2?.map((note, index) => {
      let values = [
        note?.note,
        <Profile key={cntr++} user={note.createdBy} value="full" />,
        moment(note.dateCreated).format("M/D/YYYY"),
        moment(note.dateCreated).format("h:mm A"),
      ];

      let form = (
        <form>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Input label="Note" data={note.note} />
          </div>
        </form>
      );

      return (
        <Row
          key={cntr++}
          index={index}
          id={note.id}
          data={values}
          actions="delete"
          disabled={disabled}
          handleAction={handleNoteDelete}
          form={form}
        />
      );
    });
  }

  //Return the note values This is where the formatting for notes will begin
  return (
    <>
      <GetUser />
      <h2>
        <center>Comments</center>
      </h2>
      <FlexSpace>
        <Button label="New" onClick={() => setShowModal(true)} />
      </FlexSpace>
      {showModal && (
        <Modal
          heading="Add New Note"
          setShowModal={setShowModal}
          saveButton={true}
          saveButtonDisabled={disabled}
          saveFunction={handleNotesSave}
          content={
            <>
              <GetNSTDropDown defaultValue={1} onSelectItem={handleSelectNST} />
              <Input
                data={currentNote ?? ""}
                readonly={false}
                label="Description:"
                maxlength={256}
                onChange={handleInputText}
                align="left"
              />
            </>
          }
        ></Modal>
      )}
      <StyledTable>
        <TableHead>
          <tr>
            <RoundLeft scope="col" width="1%">
              n
            </RoundLeft>
            <th scope="col" width="60%">
              Note
            </th>
            <th scope="col" width="15%">
              Author
            </th>
            <th width="10%">Date</th>
            <th width="10%">Time</th>
            <RoundRight scope="col" className="text-center" width="10%">
              Actions<i className="fa-regular fa-chart-tree-map"></i>
            </RoundRight>
          </tr>
        </TableHead>
        <TableBody>
          {noteAction === "saveNotes" ? (
            <tr>
              <td>New</td>
              <td></td>
              <td>{userName?.fullName}</td>
              <td>{dateDisplay}</td>
              <td>{timeDisplay}</td>
              <td>
                <Button
                  disabled={disabled}
                  onClick={() => handleNotesCancel()}
                  label="Cancel"
                >
                  Cancel
                </Button>
              </td>
            </tr>
          ) : (
            ""
          )}
          {rows}
        </TableBody>
      </StyledTable>
    </>
  );
};
export default Notes;
