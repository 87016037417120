import { useState } from "react";
import { Label } from "./Input.style";

import { FlexRow, FlexRowRight } from "./Input.style";
import styled from "styled-components";

const Input = (props) => {
  const {
    placeholder = "",
    readonly,
    list = "",
    maxlength = 0,
    width = null,
    onChange = null,
    marginRight,
    rows = null,
  } = props;

  const [value, setValue] = useState(null);
  const [touched, setTouched] = useState(false);
  const [charCount, setCharCount] = useState(0);

  const handleChange = (e) => {
    let wrkStr = e.target.value;
    const maxLng = maxlength;

    setCharCount(e.target.value?.length || 0);
    if (e.target.value?.length > 0) {
      const element = document.getElementById("noteArea");

      element?.setAttribute("style", "border:1px solid rgb(179, 179, 179)");
    }
    if (
      maxLng === null ||
      maxLng === undefined ||
      maxLng !== 0 ||
      maxLng >= wrkStr.length
    )
      setValue(wrkStr);

    setTouched(true);

    if (typeof onChange === "function") onChange(e);
  };

  return (
    <>
      {props.align === "left" ? (
        <>
          <FlexRow>
            <Label>{props?.label}</Label>
            <Container readonly={readonly} id="noteArea">
              <StyledTextarea
                maxLength={maxlength}
                type="textarea"
                value={value ?? props?.data ?? ""}
                onChange={handleChange}
                placeholder={placeholder}
                list={list}
                readOnly={readonly}
                touched={touched}
                rows={rows ? rows : 1}
              ></StyledTextarea>
            </Container>
          </FlexRow>
          <FlexRowRight>
            <span>
              {charCount} / {maxlength}
            </span>
          </FlexRowRight>
        </>
      ) : (
        <div
          style={{
            float: "left",
            marginBottom: ".5rem",
            display: "inline",
            width: `${width}`,
            marginRight: `${marginRight}`,
          }}
        >
          <Label>{props?.label}</Label>
          <Container readonly={readonly}>
            <StyledInput
              type="textarea"
              value={value ?? props?.data ?? ""}
              onChange={handleChange}
              placeholder={placeholder}
              list={list}
              readOnly={readonly}
              touched={touched}
              rows={rows ? rows : 1}
            />
          </Container>
        </div>
      )}
    </>
  );
};
export default Input;

const Container = styled.div`
  min-height: 25px;
  min-width: 50px;
  width: ${(props) => props?.width};
  margin-right: ${(props) => props.marginRight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #b3b3b3;
  background: ${(props) => (props.readonly ? "var(--lightGrey)" : "#fff")};
  border-radius: 5px;
  cursor: ${(props) => (props.readonly ? "default" : "auto")};
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  flex: 1 0;
  border: 0;
  background-color: transparent;
  cursor: ${(props) => (props.readonly ? "default" : "auto")};

  &:focus {
    outline: none;
  }

  ${({ touched }) =>
    touched &&
    `
        font-weight: 700;
        
    `}
`;

const StyledTextarea = styled.textarea`
  box-sizing: border-box;
  flex: 1 0;
  border: 0;
  background-color: transparent;

  &:focus {
    outline: none;
  }
`;
