import { StyledButton } from "./Button.styles";

const Button = (props) => {
  const {
    label,
    arrow = "",
    disabled = props.disabled || false,
    id,
    onClick,
    className,
    type,
  } = props; //destructuring

  let icon = "";

  if (label === "View") {
    //icon = 'fa-solid fa-up-right-and-down-left-from-center';
    if (arrow === "down") {
      icon = "fa-solid fa-chevron-down";
    } else if (arrow === "right") {
      icon = "";
    }
  } else if (label === "Collapse") {
    //icon = 'fa-solid fa-up-right-and-down-left-from-center';
    icon = "fa-solid fa-chevron-left";
  } else if (label === "Edit") {
    icon = "fa-solid fa-pencil";
  } else if (label === "New") {
    icon = "fa-solid fa-plus";
  } else if (label === "Save" || label === "Save Changes") {
    icon = "fa-regular fa-floppy-disk";
  } else if (label === "Add") {
    icon = "fa-solid fa-plus";
  } else if (label === "Cancel" || label === "Clear Changes") {
    icon = "fa-solid fa-xmark";
  } else if (label === "Delete") {
    icon = "fa-solid fa-trash-can";
  } else if (label === "Confirm" || label === "Confirm Changes") {
    icon = "fa-solid fa-check";
  } else if (label === "Submit") {
    icon = "fa-solid fa-check";
  }

  if (disabled === true) {
    icon = "fa-solid fa-lock";
  }

  return (
    <StyledButton
      className={className}
      id={id}
      onClick={onClick}
      disabled={disabled}
    >
      <i className={icon}></i>&nbsp;
      {label}
    </StyledButton>
  );
};

export default Button;
