import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const { instance, inProgress, accounts } = useMsal();
  const navigate = useNavigate();

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  useEffect(() => {
    if (inProgress === "none" && accounts.length > 0) handleLogout();

    if (inProgress === "none" && !accounts.length > 0) {
      navigate("/login");
    }
  }, [inProgress, accounts]);

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <button onClick={handleLogout} label="login">
          Logout
        </button>
      </div>
    </div>
  );
};
export default Logout;
