import {
  TableRow,
  TableHead,
  RoundLeft,
  RoundRight,
} from "../../components/Common/Styles/TableStyles";

import Button from "components/Common/Button/Button";
import ReactLoading from "react-loading";

import { useGlobalContext } from "../../components/Common/GlobalContext/GlobalContext";

export function getSelectableBuildingHeader() {
  return (
    <TableHead>
      <tr>
        <RoundLeft scope="col" width="2%">
          n
        </RoundLeft>
        <th scope="col">Name</th>
        <th scope="col">Location</th>
        <RoundRight scope="col">Add</RoundRight>
      </tr>
    </TableHead>
  );
}

export function getSelectableBuildingRows(
  rows,
  isLoading,
  isError,
  error,
  results,
  saveResults,
  saveBuilding,
  building,
  saveSearch,
  search,
  saveSrch,
  roomsSelected,
  setRoomsSelected,
  buildingsSelected,
  setBuildingsSelected,
  keysSelected,
  setKeysSelected,
  offset
) {
  let srch = 0;

  const onChange = async (e, buildingSelected, index) => {
    //check if building is already in list
    if (buildingsSelected.some((building1) => building1 === buildingSelected)) {
      return;
    }
    setBuildingsSelected((buildingsSelected) => [
      ...buildingsSelected,
      buildingSelected,
    ]);

    e.target.disabled = true;
    e.target.innerHTML = "Added";

    //filter it out of rows.current
    rows.current = rows.current.filter((building) => {
      return building.bl_id !== buildingSelected.bl_id;
    });

    buildingSelected.index = index;
  };

  if (isLoading) {
    rows.current = (
      <TableRow key={1}>
        <td style={{ textAlign: "center" }} colSpan="6">
          Loading...
          <br />
          <ReactLoading type="bubbles" color="#000000" height="300" />
        </td>
      </TableRow>
    );
  } else if (isError) {
    rows.current = (
      <TableRow key={1}>
        <td>{error.message}</td>
      </TableRow>
    );
  } else {
    try {
      if (
        saveResults?.current === results &&
        saveBuilding?.current === building &&
        saveSearch?.current === search
      ) {
        srch = -1;
      } else {
        saveResults.current = results;
        saveBuilding.current = building;
        saveSearch.current = search;

        /*
        let filter = building?.toString() ?? "";

        if (
          filter !== "" &&
          results !== undefined &&
          !results.some((item) => item.bl_id.includes(filter))
        )
          filter = "";


        //filter out buildings that are already in buildingsSelected
        if (buildingsSelected.length > 0) {
          results = results.filter((building) => {
            return !buildingsSelected.some((item) => {
              return item === building;
            });
          });
        }
        */

        rows.current = results
          ?.filter((n) => {
            if (search !== "") {
              srch = srch ?? 0;

              if (
                n.bl_name?.toLowerCase().includes(search?.toLowerCase()) ||
                //Sometimes the address is in the building name and not stored in the address string
                n.address1?.toLowerCase().includes(search?.toLowerCase())
              ) {
                srch++;
              } else {
                return false;
              }
            } else {
              srch = results?.length;
            }

            return n;

            /*
            if (filter !== "") if (n.bl_id !== filter) return false;

            if (search !== "" || filter !== "") {
              srch = srch ?? 0;

              if (n.rm_id?.toLowerCase().includes(search?.toLowerCase()))
                srch++;
              else if (n.bl_name?.toLowerCase().includes(search?.toLowerCase()))
                srch++;
              else if (
                n.address1?.toLowerCase().includes(search?.toLowerCase())
              )
                srch++;
              else return false;
            } else srch = results?.length;

            return n;
            */
          })
          .map((building, index) => (
            <TableRow key={building.bl_id}>
              <td>{index + 1}</td>
              <td>{building?.bl_name}</td>
              <td>{building?.address1}</td>
              <td>
                <Button
                  onClick={(e) => onChange(e, building, index + 1)}
                  id={building.bl_id}
                  label="Add"
                />
              </td>
            </TableRow>
          ));
      }
    } catch (error) {
      console.error("ERROR (getBuildingListTable) " + error.message);
      return (
        <TableRow key={1}>
          <td>{error.message}</td>
        </TableRow>
      );
    }
  }

  if (srch > -1) saveSrch.current = srch;
}

export function getRemovableBuildingHeader() {
  return (
    <TableHead>
      <tr>
        <RoundLeft scope="col" width="2%">
          n
        </RoundLeft>
        <th scope="col">Name</th>
        <th scope="col">Location</th>
        <RoundRight scope="col">Remove</RoundRight>
      </tr>
    </TableHead>
  );
}

export function getRemoveableBuildingRows(
  buildingsSelected, // Add buildingsSelectedsSelected as a parameter
  setBuildingsSelected // Add setBuildingsSelectedsSelected as a parameter
) {
  const onChange = (building) => {
    const newBuildings = buildingsSelected.filter((building1) => {
      return building1 !== building;
    });

    setBuildingsSelected(newBuildings);

    //enable button
    const button = document.getElementById(building.bl_id);
    if (button) {
      button.disabled = false;
      button.innerHTML = "Add";
    }
  };

  const rows = buildingsSelected.map((building, index) => (
    <TableRow key={building.bl_id}>
      <td>{index + 1}</td>
      <td>{building?.bl_name}</td>
      <td>{building?.address1}</td>
      <td>
        <Button onClick={() => onChange(building)} label="Remove" />
      </td>
    </TableRow>
  ));

  return rows;
}
