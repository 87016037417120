import React from "react";
import { useEffect, useState } from "react";
import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";
import {
  getRemovableRoomHeader,
  getRemoveableRoomRows,
} from "hooks/Rooms/roomsTableFunctions";
import { StyledTable } from "components/Common/Styles/TableStyles";

const RoomsSelected = () => {
  const { roomsSelected, setRoomsSelected } = useGlobalContext();

  const [rows, setRows] = useState(null);
  const [header, setHeader] = useState(null);

  useEffect(() => {
    setRows(getRemoveableRoomRows(roomsSelected, setRoomsSelected));
    setHeader(getRemovableRoomHeader());
  }, [roomsSelected]);

  return (
    <>
      {roomsSelected.length === 0 ? (
        <p
          style={{
            textAlign: "center",
            margin: "0 auto",
            marginBottom: "1rem",
            color: "var(--illiniDarkBlue)",
          }}
        >
          No rooms selected
        </p>
      ) : (
        <StyledTable>
          {header}
          <tbody>{rows}</tbody>
        </StyledTable>
      )}
    </>
  );
};

export default RoomsSelected;
