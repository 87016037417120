import Button from "../Button/Button";
import { useMsal } from "@azure/msal-react";
import Logo from "../../../images/block-i-white-background.png";
import {
  Wrapper,
  Content,
  MainContent,
  UIUCContent,
  LogoImage,
} from "./Header.styles";

const Header = () => {
  const { instance } = useMsal();

  const handleSignOut = () => {
    instance.logoutRedirect();
  };

  return (
    <Wrapper>
      <UIUCContent>
        <a href="https://illinois.edu/">
          <p>University of Illinois Urbana-Champaign</p>
        </a>
      </UIUCContent>
      <MainContent>
        <Content>
          <div>
            <a href="https://illinois.edu/">
              <LogoImage
                src={Logo}
                alt="University of Illinois Keys Management Application"
              />
            </a>
            <a href="/">
              <p className="unitTitleLarge">
                Keys{" "}
                {process.env.REACT_APP_ENV !== "production" ? (
                  <> - {process.env.REACT_APP_ENV}</>
                ) : null}
              </p>
            </a>
          </div>
          <div className="center"></div>
        </Content>
      </MainContent>
    </Wrapper>
  );
};

export default Header;
