import { FooterWrapper, MainContent, LinkContent } from "./Footer.styles";
import universityLogo from "../../../images/universityLogo.png";
import facebook from "../../../images/Facebook.svg";
import twitter from "../../../images/twitter.svg";
import linkedin from "../../../images/LinkedIn.svg";
import youtube from "../../../images/youtube.svg";
import instagram from "../../../images/instagram.svg";

const Footer = () => (
  <FooterWrapper>
    <MainContent>
      <div>
        <img
          src={universityLogo}
          alt="University of Illinois, Urbana-Champaign full logo"
        ></img>
      </div>
      <div>
        <a href="https://www.facebook.com/uofifs" className="PrimaryLink">
          <img className="icon" src={facebook} alt="Facebook"></img>
        </a>
        <a href="https://www.youtube.com/user/UofIFS" className="primaryLink">
          <img className="icon" src={youtube} alt="youtube"></img>
        </a>
        <a
          href="https://www.linkedin.com/company/university-of-illinois-facilities-services/"
          className="primaryLink"
        >
          <img className="icon" src={linkedin} alt="Linked in"></img>
        </a>
        <a href="https://www.instagram.com/uofifs/" className="primaryLink">
          <img className="icon" src={instagram} alt="Instagram"></img>
        </a>
        <a href="https://twitter.com/uofifs" className="primaryLink">
          <img className="icon" src={twitter} alt="Twitter"></img>
        </a>
      </div>
    </MainContent>
    <LinkContent>
      <p>
        <span className="unitTitleLarge">Facilities & Services</span>
        <br />
        <span className="paragraphSmallBlue">1501 South Oak Street,</span>
        <br />
        <span className="paragraphSmallBlue">Champaign, IL 61820</span>
        <br />
        <span className="secondaryLink underline">
          <a href="tel:2173330340">(217) 333-0340</a>
        </span>
        <br />
        <span className="secondaryLink underline">
          <a href="mailto:fsserviceoffice@illinois.edu">
            fsserviceoffice@illinois.edu
          </a>
        </span>
        <br />
      </p>
    </LinkContent>
  </FooterWrapper>
);

export default Footer;
