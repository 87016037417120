import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { instance, inProgress, accounts } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    //if the login is not in progress and there are no accounts, redirect to login
    if (inProgress === "none" && !accounts.length > 0) {
      instance.loginRedirect();
    }

    //if the login is not in progress and there are accounts, redirect to home
    if (inProgress === "none" && accounts && accounts.length > 0) {
      navigate("/");
    }
  }, [inProgress, accounts]);

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        {/* <p>
          If your browser does not automatically redirect you, please use the
          button below to login.
        </p>
        {/* <button onClick={signIn} label="login">
          Login
        </button> */}
      </div>
    </div>
  );
};

export default Login;
