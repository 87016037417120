import React, { useState } from "react";
import Button from "../Button/Button";
import styled, { css } from "styled-components";

const Row = (props) => {
  const {
    n = true,
    index,
    id = null,
    data,
    actions = "",
    handleAction,
    form,
    disabled = false,
  } = props;

  const [action, setAction] = useState("");

  const handleConfirm = () => {
    handleAction("confirm", id);
    setAction("");
  };

  const handleSave = () => {
    handleAction("save", id);
    setAction("");
  };

  const handleView = () => {
    handleAction("view", index);
  };

  return (
    <StyledTr
      style={{
        backgroundColor: action !== "" ? "#f2f2f2" : "",
      }}
    >
      <td width="5%">{n === true && <>{index + 1}</>}</td>

      {action === "edit" ? (
        <td key={index} colSpan={data.length}>
          {form}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              disabled={disabled}
              label="Save"
              onClick={() => handleSave()}
            />
            <Button disabled={disabled} label="Cancel" onClick="" />
          </div>
        </td>
      ) : (
        <>
          {data?.map((value, index) => (
            <td key={index}>{value}</td>
          ))}
        </>
      )}

      {actions !== "" && (
        <td width="15%">
          <div style={{ display: "flex", flexDirection: "row" }}>
            {actions.includes("delete") &&
              (action === "delete" ? (
                <>
                  <Button
                    disabled={disabled}
                    onClick={() => handleConfirm()}
                    label="Confirm"
                  >
                    Confirm
                  </Button>
                  <Button
                    disabled={disabled}
                    onClick={() => setAction("")}
                    label="Cancel"
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    disabled={disabled}
                    onClick={() => setAction("delete")}
                    label="Delete"
                  >
                    Delete
                  </Button>
                </>
              ))}

            {actions.includes("edit") &&
              action !== "delete" &&
              (action === "edit" ? (
                <>
                  <Button
                    disabled={disabled}
                    onClick={() => setAction("")}
                    label="Cancel"
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    disabled={disabled}
                    onClick={() => setAction("edit")}
                    label="Edit"
                  >
                    Edit
                  </Button>
                </>
              ))}

            {actions.includes("view") && (
              <>
                <Button
                  disabled={disabled}
                  onClick={() => handleView()}
                  label="Details"
                ></Button>
              </>
            )}
          </div>
        </td>
      )}
    </StyledTr>
  );
};
export default Row;

const StyledTr = styled.tr`

background-color: props.action==='delete'?'#F2F2F2':'' ;


    ${(props) => props.action && css``}

`;
