import {
  ModalBox,
  ModalHeading,
  CenterModal,
  Heading,
  DarkBackground,
  ModalContent,
  Flex,
} from "./Modal.styles";
import Button from "../Button/Button";

export const Modal = ({
  content,
  setShowModal,
  heading,
  saveButton,
  saveButtonDisabled,
  saveFunction,
}) => {
  return (
    <>
      <DarkBackground onClick={() => setShowModal(false)}></DarkBackground>
      <CenterModal>
        <ModalBox>
          <ModalHeading>
            <Heading>{heading ? heading : <>Details</>}</Heading>
          </ModalHeading>
          <ModalContent>{content}</ModalContent>
          <Flex>
            <Button onClick={() => setShowModal(false)} label="Close"></Button>
            {saveButton && (
              <Button
                onClick={() => saveFunction()}
                disabled={saveButtonDisabled}
                label="Save"
              />
            )}
          </Flex>
        </ModalBox>
      </CenterModal>
    </>
  );
};
