import styled from "styled-components";

export const Label = styled.label`
  font-weight: 700;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: flex-beginning;

  width: 100%;
  label {
    padding-right: 1rem;
    font-size: 1rem;
  }
  > div {
    flex: 1;
  }
`;

export const FlexRowRight = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 0 !important;
`;
