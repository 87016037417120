import React, { useEffect } from "react";
import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";
import { useQuery } from "react-query";
import Select from "components/Common/Select/Select";
import { getNST } from "hooks/Key/keyData";
import { Label } from "components/Common/Input/Input.style";

import { FlexRow } from "./getNSTDropDown.style";

export function GetNSTDropDown({
  onSelectItem, //function that gets the selected value
  setFirstValue, //set the default value
  onRefresh, //function called after data is loaded
  totalCountCallback, //total items in dropdown
  defaultOption, //Set the default based on the text provided
  defaultValue, //set the default
}) {
  const { noteShareTypes, setNoteShareTypes } = useGlobalContext();
  const key = "";

  const handleSelectNST = (item) => {
    if (typeof onSelectItem === "function") onSelectItem(item);
  };

  const { data, isLoading, refetch, isError, error } = useQuery(
    ["NST"],
    () => getNST(),
    {
      select: (data) => {
        const nst = data?.data.results ?? [{ id: 1, name: "All Users" }];

        return nst;
      },
      enabled: false,
      onSuccess: (data) => {
        if (!noteShareTypes[key]) {
          setNoteShareTypes({
            ...noteShareTypes,
            [key]: data,
          });
          if (typeof onRefresh === "function") onRefresh();
          if (typeof totalCountCallback === "function")
            totalCountCallback(data?.length ?? 0);
        }
      },
      fetchPolicy: "cache-first",
    }
  );

  useEffect(() => {
    if (!isLoading && !noteShareTypes[key]) {
      if (!noteShareTypes[key]) refetch();
    }
  }, [isLoading, noteShareTypes, refetch]);

  return (
    <>
      {isLoading ? (
        <Select
          width="450px"
          label="Note Share Type"
          optionsheader="-- Loading --"
        />
      ) : isError ? (
        <Select
          width="450px"
          label="Note Share Type"
          optionsheader={error.message}
        />
      ) : (
        <FlexRow>
          <Label>Note Share Type:</Label>
          <Select
            width="450px"
            defaultValue={defaultValue}
            defaultOption={defaultOption}
            options={noteShareTypes[key] ?? data}
            onChange={(e) => handleSelectNST(e)}
          />
        </FlexRow>
      )}
    </>
  );
}
