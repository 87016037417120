import { ReactQueryDevtools } from "react-query/devtools";
import { MsalProvider } from "@azure/msal-react";
import { GlobalProvider } from "components/Common/GlobalContext/GlobalContext";
import { QueryClient, QueryClientProvider } from "react-query";
import AppRoutes from "router";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

function App({ msalInstance }) {
  msalInstance.initialize();
  return (
    <MsalProvider instance={msalInstance}>
      <GlobalProvider>
        <QueryClientProvider client={queryClient}>
          <AppRoutes />
          <ReactQueryDevtools />
        </QueryClientProvider>
      </GlobalProvider>
    </MsalProvider>
  );
}

export default App;
