import styled from "styled-components";

export const Container = styled.div`
  cursor: pointer;
  margin: auto;
  width: 100%;

  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;
