import styled from "styled-components";

export const FlexRow = styled.div`
  display: flex;
  justify-content: flex-beginning;

  label {
    padding-right: 1rem;
    font-size: 1rem;
  }
`;
