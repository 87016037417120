import React, { useState } from "react";
import { useParams } from "react-router-dom";

import {
  FlexContainer,
  FullWidth,
  Break,
  HalfWidth,
  HalfWidth2,
  CenterStyle,
} from "./getRoomDetail.style";

import Panel from "components/Common/Panel/Panel";
import Input from "components/Common/Input/Input";

import GetRoomAssignments from "../getRoomAssignments/getRoomAssignments";
import Notes from "components/Common/useNotes/useNotes";

import Button from "components/Common/Button/Button";

import { useMutation, useQuery, useQueryClient } from "react-query";

import { getRoomGeneral } from "hooks/Rooms/roomGeneral";
import { getKeyRooms, execRooms } from "hooks/Key/keyData";

import { GetEACDropDown } from "components/Context/getEACDropDown/getEACDropDown";

import GetRoomKeysTable from "components/Rooms/getRoomKeysTables/getRoomKeysTables";
import { GetRoomsSVG } from "components/Context/getRoomsSVG/getRoomsSVG";

const GetRoomDetail = ({ reloadQuery = false }) => {
  const { id } = useParams();
  const [room, setRoom] = useState({});
  const [keyRoom, setKeyRoom] = useState({});
  const [queryRoom, setQueryRoom] = useState(null);
  const [queryKeyRoom, setQueryKeyRoom] = useState(null);
  const [disabledRoom, setDisabledRoom] = useState(false);

  const queryClient = useQueryClient();
  let additionalDataVal = null;

  const roomsWrite = {
    unitId: room?.dp_id, // for security check
    datas: {
      roomId: id,
      EAC: keyRoom?.EAC,
      additionalData: additionalDataVal, // future
    },
  };

  // We should never need these values for a room specific query
  const keysFilter = false; //Determine if Keys should be filtered (This will force using the keys database)
  const keysFilterDatabase = false; //Use the keys database

  // change query to use
  // https://aimintegrationapim.azure-api.net/archibusdata/archibusnew/percentage?array=true&verbosity=0&bl_id=0197&fl_id=02&rm_id=W0223&extra_fields=bl_id, bl_name, banner_name_abrev, on_off_campus, fl_id, fl_name, rm_id, rm_status, rm_area, rm_cat, cat_description, use_description, rm_use, type_description, rm_type, dp_id, dp_name, dp_head, dp_option2, dv_name, dv_id, dv_head, dv_option2, chart_id, chart_name&chart_id=1&dv_id=KL0&dp_id=802
  // Add chart_id as an input parm to the percentage API
  // dept will be sent for security If the user has multiple departments the department code will not be sent so all departments will be displayed

  let idArray = id.split("-");

  const BuildingId = idArray[0];
  const FloorId = idArray[1];
  const RoomId = idArray[2];

  const roomQuery = useQuery(
    ["id", BuildingId, FloorId, RoomId],
    () =>
      getRoomGeneral(
        "all",
        "3600",
        null,
        null,
        BuildingId,
        FloorId,
        RoomId,
        keysFilter,
        keysFilterDatabase
      ),
    {
      //getRoom(id), {
      enabled: queryRoom !== id || reloadQuery === true,
      onSuccess: (data) => {
        const result = data?.data?.results;

        setQueryRoom(id);

        if (result && result.length > 0) {
          setRoom(result[0]);
        }
      },
      fetchPolicy: "cache",
    }
  );

  //Load additional rooms data from the keys database
  const roomKeyQuery = useQuery(["idKey", id], () => getKeyRooms(id), {
    enabled: queryKeyRoom !== id || reloadQuery === true,
    onSuccess: (data) => {
      const result = data?.data?.results;

      setQueryKeyRoom(id);

      if (result && result.length > 0) {
        setKeyRoom(result[0]);
      }
    },
    onError: (error) => {
      console.error(error);
    },
    fetchPolicy: "cache",
  });

  const updateRoom = useMutation({
    mutationFn: (roomsWrite) => {
      return execRooms(roomsWrite);
    },
    onSuccess: (data) => {
      const result = data?.data?.results;
      if (result?.length ?? 0 >= 1) {
        alert(result[0]?.message ?? "Save processed");
        setDisabledRoom(false);
      }

      queryClient.invalidateQueries(["idKey", id]);
    },
    onError: (error) => {
      console.log("error (updateRoom)", error);
      setDisabledRoom(false);
    },
  });

  const roomId =
    roomQuery.isLoading || roomKeyQuery.isLoading ? (
      "-- Loading --"
    ) : roomQuery.isError ? (
      roomQuery.error.message
    ) : room?.rm_id === undefined || room?.rm_id === null ? (
      "Room Number: Not Found"
    ) : (
      <GetRoomsSVG
        building={room.bl_id}
        floor={room.fl_id}
        room={room.rm_id}
        detail="RoomDetail"
      />
    );

  const isInvalid =
    roomQuery.isLoading ||
    roomKeyQuery.isLoading ||
    roomQuery.isError ||
    room?.rm_id === null ||
    room?.rm_id === undefined
      ? true
      : false;

  const handleSelectEAC = (item) => {
    keyRoom.EAC = item;
    roomsWrite.datas.EAC = item;
  };

  const handleSaveRoom = () => {
    setDisabledRoom(true);
    updateRoom.mutate(roomsWrite);
  };

  return (
    <div>
      <div className="container">
        <CenterStyle>
          <Panel
            label=""
            data={roomId}
            headers=""
            style={{ margin: "0 auto", dislpay: "block" }}
            actions={[room?.rm_status]}
          />
        </CenterStyle>

        {isInvalid ? (
          <></>
        ) : (
          <>
            <FlexContainer>
              <HalfWidth>
                <h3>Physical Location</h3>

                <Input
                  width="100%"
                  label="Building Name (Code) (Class)"
                  data={
                    room?.bl_name +
                    " (" +
                    room?.bl_id +
                    ")" +
                    " (" +
                    room?.banner_name_abrev +
                    ")"
                  }
                  readonly={true}
                />

                <Input
                  width="100%"
                  label="Floor (Number)"
                  data={room?.fl_name + " (" + room?.fl_id + ")"}
                  readonly={true}
                />

                <Input
                  width="100%"
                  label="Square Foot Area"
                  data={room?.rm_area}
                  readonly={true}
                />

                <Input
                  width="100%"
                  label="On/Off Campus"
                  data={room?.on_off_campus}
                  readonly={true}
                  style={{ width: "200px" }}
                />
              </HalfWidth>

              <HalfWidth>
                <h3>Category and Use</h3>
                <Input
                  width="100%"
                  label="Use Category (Code)"
                  data={room?.cat_description + " (" + room?.rm_cat + ")"}
                  readonly={true}
                />

                {/* <Input
                  width="100%"
                  label="Use (Code)"
                  data={room?.use_description + " (" + room?.rm_use + ")"}
                  readonly={true}
                /> */}

                <Input
                  width="100%"
                  label="Actual Room Use (Code)"
                  data={room?.type_description + " (" + room?.rm_type + ")"}
                  readonly={true}
                />

                <Input
                  width="100%"
                  label="Percentage"
                  data={parseFloat(room?.pct_space ?? 0).toFixed(2) + "%"}
                  readonly={true}
                />
              </HalfWidth>

              <Break />

              <HalfWidth>
                <h3>Ownership</h3>
                <Input
                  width="100%"
                  label="College (Code)"
                  data={room?.bu_name + " (" + room?.bu_id + ")"}
                  readonly={true}
                />
                <Input
                  width="100%"
                  label="Department (Code)"
                  data={room?.dv_name + " (" + room?.dv_id + ")"}
                  readonly={true}
                />
                <Input
                  width="100%"
                  label="Department Occupant"
                  data={room?.dv_head + " (" + room?.dv_option2 + ")"}
                  readonly={true}
                />
                <Input
                  width="100%"
                  label="Unit (Code)"
                  data={room?.dp_name + " (" + room?.dp_id + ")"}
                  readonly={true}
                />
                <Input
                  width="100%"
                  label="Unit Occupant"
                  data={room?.dp_head + " (" + room?.dp_option2 + ")"}
                  readonly={true}
                />
              </HalfWidth>
              <HalfWidth>
                <h3>Electronic Access</h3>
                <HalfWidth2>
                  <GetEACDropDown
                    onSelectItem={handleSelectEAC}
                    defaultValue={keyRoom?.EAC}
                  />
                </HalfWidth2>

                <HalfWidth2>
                  <Button
                    label="Save"
                    onClick={handleSaveRoom}
                    disabled={disabledRoom}
                  />
                </HalfWidth2>
              </HalfWidth>
            </FlexContainer>

            <span style={{ width: "100%", height: "1.5em" }}></span>
            <FullWidth>
              <GetRoomKeysTable id={id} />
            </FullWidth>

            <FullWidth>
              <GetRoomAssignments id={id} />
            </FullWidth>

            <FullWidth>
              <Notes roomId={id} />
            </FullWidth>
          </>
        )}
      </div>
    </div>
  );
};

export default GetRoomDetail;
