// git command to add a local branch
// git switch -c add-templating remotes/origin/add-templating
import { useState, useEffect } from "react";
import { ExcelUpload } from "components/Uploads/ExcelUpload/ExcelUpload";
import { getRoles } from "hooks/User/userRoles";

const Uploads = () => {
  const [roles, setRoles] = useState(null);

  useEffect(() => {
    if (roles === null) {
      getRoles().then((response) => {
        setRoles(response.data);
      });
    }
  }, []);

  return (
    <div>
      {roles?.includes(1) || roles?.includes(2) ? (
        <>
          <b>Keys Data Uploads</b>
          <ExcelUpload
            title="KeysMaster Table Upload"
            backEndCall="/api/requests/execKeysMasterMassUpload"
            defaultBatchSize={2000}
          />
          <ExcelUpload
            title="KeysRooms Table Upload"
            backEndCall="/api/requests/execKeysRoomsMassUpload"
            defaultBatchSize={2000}
          />
          <ExcelUpload
            title="KeysAssignments Table Upload"
            backEndCall="/api/requests/execKeysAssignmentsMassUpload"
            defaultBatchSize={2000}
          />
        </>
      ) : (
        <></>
      )}
      {roles?.includes(1) || roles?.includes(3) ? (
        <>
          <b>Rooms Data Uploads</b>
          <ExcelUpload
            title="Percentage Table Upload"
            backEndCall="/api/requests/execPercentageMassUpload"
            defaultBatchSize={500}
          />
        </>
      ) : (
        <></>
      )}
      {roles?.includes(1) ? (
        <>
          <b>Authorization Data Uploads</b>
          <ExcelUpload
            title="AuthMap Table Upload"
            backEndCall="/api/requests/execAuthMapMassUpload"
          />
        </>
      ) : (
        <></>
      )}
      {roles?.includes(1) || roles?.includes(2) || roles?.includes(3) ? (
        <>
          <b>Email Owner Uploads</b>
          <ExcelUpload
            title="emOccupancyEmail Table Upload"
            backEndCall="/api/requests/execEmMassUpload"
            defaultBatchSize={5000}
          />
        </>
      ) : (
        <></>
      )}
      {roles?.includes(1) ? (
        <>
          <b>Email List Upload From ICard</b>
          <ExcelUpload
            title="iCardDaily Table Upload"
            backEndCall="/api/requests/execICardDailyMassUpload"
            defaultBatchSize={5000}
          />
        </>
      ) : (
        <></>
      )}
      {roles?.includes(1) ||
      roles?.includes(
        5
      ) /* The value 5 is for a role that supports admin roles for specific departments */ ? (
        <>
          <b>Authorization List Upload for Authority</b>
          <ExcelUpload
            title="authz Table Upload"
            backEndCall="/api/requests/execAuthzMassUpload"
            defaultBatchSize={5000}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default Uploads;
