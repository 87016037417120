import styled from "styled-components";

export const Input = styled.input`
  border: 1px solid #b3b3b3;
  margin: 5px;
  padding: 10px 20px;

  border-radius: 4px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0.5rem;
  padding: 0.5rem;
  border: 0.125rem solid var(--illiniDarkBlue);
  border-radius: 0.5rem;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 15rem;
  margin: 0.5rem;
  padding: 0.5rem;
  border: 0.125rem solid var(--illiniDarkBlue);
  border-radius: 0.5rem;
`;
