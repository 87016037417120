import React, { useState, useEffect } from "react";
import { StyledTable } from "components/Common/Styles/TableStyles";

import {
  getApprovalHeader,
  getApprovalRows,
} from "hooks/Approvals/ApprovalTableFunctions";

import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";
import CircularProgress from "@mui/material/CircularProgress";
import { useMsal } from "@azure/msal-react";

const ApprovalsTable = () => {
  const [header, setHeader] = useState(null);
  const [approvalsLoaded, setApprovalsLoaded] = useState(false);

  const { accounts } = useMsal();

  const userName = accounts[0]?.idTokenClaims;

  const { approvalRows, setApprovalRows, setAlert, loading, setLoading } =
    useGlobalContext();

  if (userName?.uin && !approvalsLoaded) {
    setLoading(true);
    getApprovalRows(userName.uin, setApprovalRows, setAlert, setLoading);
    setApprovalsLoaded(true);
  }

  if (header === null) {
    const header = getApprovalHeader();
    setHeader(header);
  }

  return (
    <>
      <div style={{ position: "relative", height: "fit-content" }}>
        {loading ? (
          <>
            <div
              style={{
                position: "absolute",
                top: "0%",
                left: "0%",
                opacity: "1",
                width: "100%",
                height: "100%",
                backgroundColor: "var(--darkGrey)",
                opacity: "0.5",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  marginTop: "3rem",
                }}
              >
                <CircularProgress />
              </div>
            </div>
            <StyledTable>
              {header}
              {approvalRows}
            </StyledTable>
          </>
        ) : (
          <StyledTable>
            {header}
            <tbody>{approvalRows}</tbody>
          </StyledTable>
        )}
      </div>
    </>
  );
};

export default ApprovalsTable;
