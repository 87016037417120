import { useState, useEffect } from "react";
import { MenuDiv, Container } from "./Menu.styles";

import { getRoles } from "hooks/User/userRoles";
import { useIsAuthenticated } from "@azure/msal-react";
import MenuItem from "./MenuItem";

const Menu = () => {
  const [roles, setRoles] = useState(null);
  const [allAccess, setAllAccess] = useState(true);
  const [keysAccess, setKeysAccess] = useState(true);
  const [roomsAccess, setRoomsAccess] = useState(true);
  const [adminAccess, setAdminAccess] = useState(true);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated && roles === null) {
      getRoles().then((response) => {
        setRoles(response.data);
      });
    }

    if (roles) {
      setAllAccess(roles?.includes(1));
      setKeysAccess(roles?.includes(2) || roles?.includes(1));
      setRoomsAccess(roles.includes(3) || roles.includes(1));
      setAdminAccess(roles.includes(5) || roles.includes(1));
    }
  }, [isAuthenticated, roles]);

  let menu = [];

  //All Access or Keys Access menu Items
  if (keysAccess) {
    menu.push({
      id: 1,
      label: "Keys",
      url: "/keys",
      childItems: {
        nodes: [],
      },
    });
    menu.push({
      id: 2,
      label: "My Approvals",
      url: "/approvals",
      childItems: {
        nodes: [],
      },
    });
  }

  //All Access or Rooms Access menu Items
  if (roomsAccess) {
    menu.push({
      id: 3,
      label: "Rooms",
      url: "/rooms",
      childItems: {
        nodes: [],
      },
    });
  }

  //all Access only menu Items
  if (allAccess || keysAccess || roomsAccess || adminAccess) {
    menu.push({
      id: 4,
      label: "Admin",
      url: "",
      childItems: {
        nodes: [
          // { id: 6, label: "My Tasks", url: "/mytasks" },
          { id: 5, label: "Uploads", url: "/uploads" },
        ],
      },
    });
  }

  //push Request Keys and User menu Items for all users
  menu.push(
    {
      id: 6,
      label: "Request Keys",
      url: "/requestkeys",
      childItems: {
        nodes: [],
      },
    },
    {
      id: 7,
      label: "User",
      url: "",
      childItems: {
        nodes: [
          // { id: 9, label: "My Requests", url: "/myrequests" },
          // { id: 10, label: "Settings", url: "" },
          { id: 8, label: "Logout", url: "/logout" },
        ],
      },
    }
  );

  return (
    <MenuDiv>
      <Container>
        <ul>
          {menu.map((mainItem) => (
            <MenuItem key={mainItem.id} mainItem={mainItem} i={mainItem.id} />
          ))}
        </ul>
      </Container>
    </MenuDiv>
  );
};

export default Menu;
