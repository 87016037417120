import styled from "styled-components";

export const CardContainer = styled.div`
  width: 400px;
  border-radius: 8px;
  background: var(--white);
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  margin-right: 1rem;
  margin-bottom: 1rem;

  .relatedService {
    display: flex;
    width: 100%;
    height: auto;
  }

  .relatedService img {
    height: 2rem;
    width: auto;
    padding: 0.5rem;
  }

  .relatedService a {
    padding-left: 0.5rem;
  }

  @media only screen and (max-width: 992px) {
    margin-right: 0;
  }

  @media only screen and (max-width: 480px) {
    margin-right: 0;
  }
`;
