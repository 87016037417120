import React, { useState } from "react";
import { TableBody, TableCell, TableRow, Paper } from "@mui/material";

import {
  StyledTable,
  TableHeader,
  TableFooter,
  TableContainerWrapper,
} from "./DynamicTable.style";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

export const DynamicTable = ({ data }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [sortedColumnIndex, setSortedColumnIndex] = useState(-1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property, columnIndex) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortedColumnIndex(columnIndex);
  };

  const sortedData = data.sort((a, b) => {
    let aValue, bValue;
    if (orderBy === "") {
      return 0;
    } else {
      aValue = a[orderBy] ?? "";
      bValue = b[orderBy] ?? "";
    }
    if (order === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const slicedData = sortedData.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );

  return (
    <div style={{ width: "100%" }}>
      <TableContainerWrapper component={Paper}>
        <StyledTable>
          <TableHeader>
            <TableRow>
              {Object.keys(data[0]).map((key, index) => (
                <TableCell
                  key={key}
                  onClick={() => handleSort(key, index)}
                  style={{
                    color: "white",
                    backgroundColor:
                      sortedColumnIndex === index ? "#2b4163" : "#13294b",
                  }}
                >
                  {key}
                  {orderBy === key &&
                    (order === "asc" ? (
                      <FontAwesomeIcon icon={faArrowUp} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowDown} />
                    ))}
                </TableCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {slicedData.map((item, index) => (
              <TableRow
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? "#ffffff" : "#f2f2f2",
                }}
              >
                {Object.values(item).map((value, i) => (
                  <TableCell key={i}>{value}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainerWrapper>
      <TableFooter
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          value: rowsPerPage,
          onChange: handleChangeRowsPerPage,
        }}
      />
    </div>
  );
};
