import { useState } from "react";
import { useQuery } from "react-query";

import { getRoomAssigments } from "hooks/Rooms/roomData";
import {
  StyledTable,
  TableRow,
  TableHead,
  TableBody,
  RoundLeft,
  RoundRight,
} from "../../Common/Styles/TableStyles";
import ReactLoading from "react-loading";
import Profile from "components/Common/Profile/Profile";
import ICard from "components/Common/icard/icard";
import Row from "components/Common/TableRow/TableRow";
import React from "react";

import { TH } from "./getRoomAssignments.style";

const GetRoomAssignments = (props) => {
  const [showDetails, setShowDetails] = useState("");
  const [queryId, setQueryId] = useState(null);

  const { id = "", reloadQuery = false } = props;

  const { isLoading, isError, error, data } = useQuery(
    ["assignments", id],
    () => getRoomAssigments(id),
    {
      enabled: queryId !== id || reloadQuery === true,
      onSuccess: () => {
        setQueryId(id);
      },
    }
  );

  const handleAction = (action, n) => {
    if (action === "view") {
      setShowDetails(showDetails === n ? "" : n);
    }
  };

  let rows;
  if (isLoading) {
    rows = (
      <TableRow>
        <td style={{ textAlign: "center" }} colSpan="6">
          Loading
          <br />
          <ReactLoading type="bubbles" color="#000000" height="300" />
        </td>
      </TableRow>
    );
  } else if (isError) {
    rows = (
      <TableRow>
        <td>{error.message}</td>
      </TableRow>
    );
  } else {
    rows = data?.data?.results.map((assignment, index) => {
      let user = [
        <Profile user={assignment.em_id} value="first" />,
        <Profile user={assignment.em_id} value="middle" />,
        <Profile user={assignment.em_id} value="last" />,
        <Profile user={assignment.em_id} value="netid" />,
        assignment.is_primary,
      ];

      let icarddetails = [
        <ICard user={assignment.em_id} value="image" />,
        <ICard user={assignment.em_id} value="badge" />,
        <ICard user={assignment.em_id} value="cardnumbers" />,
        <ICard user={assignment.em_id} value="carddates" />,
      ];

      return (
        // Need to add a React.Fragment to add a key to the fragment
        <React.Fragment key={index}>
          <Row
            data={user}
            actions="view"
            handleAction={handleAction}
            index={index}
          />
          {showDetails === index && (
            <Row n={false} data={icarddetails} index={index} />
          )}
        </React.Fragment>
      );
    });
  }

  return (
    <>
      <StyledTable>
        <TableHead>
          <tr>
            <RoundLeft scope="col" width="1%">
              n
            </RoundLeft>
            <TH scope="col" width="20%">
              First Name
            </TH>
            <TH scope="col" width="20%">
              Middle Name
            </TH>
            <TH scope="col" width="20%">
              Last Name
            </TH>
            <TH scope="col" width="20%">
              NetID
            </TH>
            <TH scope="col" width="20%">
              Primary Office
            </TH>
            <RoundRight width="20%">Actions</RoundRight>
          </tr>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </StyledTable>
    </>
  );
};
export default GetRoomAssignments;
