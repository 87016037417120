import React, { useState, useRef } from "react";
import Panel from "components/Common/Panel/Panel";
import Select from "components/Common/Select/Select";
import styled from "styled-components";

import { GetListTable } from "components/Context/getListTable/getListTable";
import { GetUnitsDropDown } from "components/Context/getUnitsDropDown/getUnitsDropDown";

import { GetBuildingsDropDown } from "components/Context/getBuildingDropDown/getBuildingDropDown";

import {
  getRoomRows,
  getRoomHeader,
} from "../../../hooks/Rooms/roomsTableFunctions";

const Rooms = () => {
  const [search, setSearch] = useState("");
  const [building, setBuilding] = useState();
  const [department, setDepartment] = useState();
  const [count, setCount] = useState({
    rooms: 0,
    buildings: 0,
    results: 0,
  });
  const [searchresults, setSearchResults] = useState(0);
  const [buildings, setBuildings] = useState([]);
  const saveBuilding = useRef("");

  const handleSelectDepartment = (e) => {
    if (e === "") {
      updateCount({
        rooms: 0,
        buildings: 0,
        results: 0,
      });
      setDepartment();
      setBuildings([]);
    } else setDepartment(e);
  };
  const handleSelectBuilding = (e) => {
    setBuilding(e);
    saveBuilding.current = e;
  };

  const handleSelectAllBuilding = (e) => {
    setBuilding(e);
    setDepartment();
    saveBuilding.current = e;
    if (e === "") {
      clearCount();
    }
  };

  function updateCount(count) {
    setCount(count);
  }

  function clearCount() {
    setCount({
      rooms: 0,
      buildings: 0,
      results: 0,
    });
  }

  function filterBuildings(data) {
    let filtered = 0;

    let buildings = data
      .filter(({ bl_id, bl_name }) => {
        const result = bl_name !== null && bl_id !== null;

        if (!result) filtered++;
        return result;
      })
      .map(({ bl_id, bl_name }) => ({
        id: bl_id,
        name: bl_name + " (" + bl_id + ")",
      }))
      .sort((a, b) => {
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

    let buildingList = [
      ...new Map(buildings.map((item) => [item["id"], item])).values(),
    ];

    // if the chosen building exists in the new list,
    //  keep it, otherwise blank out the list
    //  this removes the problem where having chosen value not
    //  exist in the department causes all data to not match

    if (
      saveBuilding.current !== "" &&
      buildingList.some((item) => item.id.includes(building))
    ) {
      setBuilding(saveBuilding.current);
    } else setBuilding();

    setBuildings(buildingList);

    updateCount({
      rooms: buildings.length + filtered,
      buildings: buildingList.length,
    });
  }

  const updateSearchCount = (srch) => {
    setSearchResults(srch);
  };

  return (
    <>
      <div>
        <KeysNav>
          <InnerNav>
            <GetUnitsDropDown onSelectItem={handleSelectDepartment} />

            {department !== undefined ? (
              <Select
                label="Building"
                optionsheader="-- Select a Building --"
                options={buildings}
                onChange={(e) => handleSelectBuilding(e)}
                defaultValue={saveBuilding.current}
              />
            ) : (
              <GetBuildingsDropDown
                onSelectItem={(e) => handleSelectAllBuilding(e)}
                defaultValue={saveBuilding.current}
              />
            )}

            <div>
              <label>Search</label>
              <br />
              <input
                id="search"
                label="Search"
                type="text"
                className="form-control"
                placeholder="Search..."
                style={{
                  height: "2.3rem",
                  border: "1px solid rgb(179, 179, 179)",
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </InnerNav>
          <InnerNav>
            <Panel label="Rooms" data={count.rooms} headers="" />

            <Panel label="Buildings" data={count.buildings} headers="" />

            <Panel label="Results" data={searchresults} headers="" />
          </InnerNav>
        </KeysNav>

        <div>
          <GetListTable
            department={department}
            building={building}
            search={search}
            updateCount={(count) => updateCount(count)}
            updateBuildings={(data) => filterBuildings(data)}
            updateSearchCount={(srch) => updateSearchCount(srch)}
            getHeader={getRoomHeader}
            getRows={getRoomRows}
          />
        </div>
      </div>
    </>
  );
};
export default Rooms;

export const KeysNav = styled.div`
  width: 1180px;
  justify-content: flex-start;
  position: relative;

  margin: 0 auto;

  @media (max-width: 1180px) {
    width: 100%;
  }
`;

export const InnerNav = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  padding: 1rem 0 1rem 0;
`;
