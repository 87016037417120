import axiosInstance from "../Axios/axiosClient";

//https://api-test.apps.uillinois.edu/person/person-data-query/{UIN}[?format]
// Desired response format. Supports either "json" (default) or "xml"

const getUser = async (user) => {
  const response = await axiosInstance.get(`/api/user/aits/id/${user}`);

  return response;
};
//APIs below this are Pending review

const getCurrentUser = async () => {
  const response = await axiosInstance.get(`/api/user/icard/`);
  return response;
};

const getUserICard = async (user) => {
  const response = await axiosInstance.get(`/api/user/icard/id/${user}`);
  return response;
};

const getSearchUser = async (first, last, netid, uin) => {
  const response = await axiosInstance.get(
    `/api/user/aits/search/&${first}&${last}&${netid}&${uin}`
  );
  return response;
};

// const getSearchUserNOTWORKIING = async (first, last, netid, uin) => {
//   let json = JSON.stringify({
//     firstName: first,
//     lastName: last,
//     netId: netid,
//     uin: uin,
//   });

//   const response = await axiosInstance.get(`/api/user/aits/search/${json}`);
//   return response;
// };

export { getCurrentUser, getSearchUser, getUser, getUserICard };
