import styled from "styled-components";

export const StyledContainer = styled.div`
  cursor: pointer;
  margin: auto;
  width: 50%;

  margin-top: 10px;
  margin-bottom: 10px;

  width: 100%;
  padding: 2.5rem;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  div {
    width: 100%;
  }


  label {
    float: left;
    font-weight: 700;
    margin-right: 1rem;
\  }

  .active {
    background: #13294b;
    color: #fff;
  }

  h4 {
    display: inline;
  }
`;
