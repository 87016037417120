import styled from "styled-components";

export const StyledContainer = styled.div`
  cursor: pointer;
  margin: auto;
  width: 50%;

  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;

  width: 100%;
  padding: 2.5rem;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  align-items: flex-end;
  flex-wrap: wrap;
  text-align: center;
  h4 {
    display: inline;
  }
`;
export const KeysNav = styled.div`
  width: 100%;
  justify-content: flex-start;
  position: relative;

  margin: 0 auto;

  @media (max-width: 1180px) {
    width: 100%;
  }
`;

export const InnerNav = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;

  padding: 1rem 0 1rem 0;
`;

export const StyledContainer2 = styled.div`
  cursor: pointer;
  margin: auto;
  width: 50%;

  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;

  width: 100%;
  padding: 2.5rem;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  align-items: space-around;
  justify-content: space-around;

  .active {
    background: #13294b;
    color: #fff;
  }

  h4 {
    display: inline;
  }
`;
