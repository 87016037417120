import { styled } from "@mui/system";

import {
  Table,
  TableContainer,
  TableHead,
  TablePagination,
} from "@mui/material";

export const StyledTable = styled(Table)(({ theme }) => ({
  "& .MuiTableCell-head": {
    fontWeight: "bold",
    backgroundColor: "#0ff",
    zIndex: 1,
    whiteSpace: "nowrap",
  },
  "& .MuiTableCell-root": {
    border: "1px solid #000",
    padding: "0.5rem",
  },
}));

export const TableContainerWrapper = styled(TableContainer)(({ theme }) => ({
  maxHeight: "calc(100vh - 200px)", // Adjust the height based on your needs
  position: "relative",
}));

export const TableHeader = styled(TableHead)(() => ({
  position: "sticky",
  top: 0,
  zIndex: 1,
  border: "1px solid #000",
  cursor: "pointer",
}));

export const TableFooter = styled(TablePagination)(() => ({
  position: "sticky",
  bottom: 0,
  zIndex: 1,
  backgroundColor: "white",
}));
